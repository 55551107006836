/** @format */

import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { createCallingOrder } from "../ApiCore";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import PowerBySnippet from "./PowerBySnippet";

const OrderingPageSpanish = () => {
	const [fullName, setFullName] = useState("");
	const [buttonClicked, setButtonClicked] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [licensePlate, setLicensePlate] = useState("");
	const [useAddress, setUserAddress] = useState("");
	const [useAddress2, setUserAddress2] = useState("");
	const [useAddress3, setUserAddress3] = useState("");
	// eslint-disable-next-line
	const [useAddress4, setUserAddress4] = useState("");
	// eslint-disable-next-line
	const [useAddress5, setUserAddress5] = useState("");
	// eslint-disable-next-line
	const [useAddress6, setUserAddress6] = useState("");
	const [locationAddress, setLocationAddress] = useState("");
	const [make, setMake] = useState("");
	const [model, setModel] = useState("");
	const [color, setColor] = useState("");
	const [chosenService, setChosenService] = useState("");

	var img_url_close = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=700x300&sensor=false&markers=color:red%7C${latitude},${longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`;

	var img_url_far = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=10&size=700x300&sensor=false&markers=color:red%7C${latitude},${longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`;

	const handleChange1 = (e) => {
		setFullName(e.target.value);
	};
	const handleChange2 = (e) => {
		setPhoneNumber(e.target.value);
	};

	const handleChange3 = (e) => {
		setLocationAddress(e.target.value);
	};

	const handleChange4 = (e) => {
		setMake(e.target.value);
	};

	const handleChange5 = (e) => {
		setModel(e.target.value);
	};

	const handleChange6 = (e) => {
		setColor(e.target.value);
	};

	const handleChange7 = (e) => {
		setLicensePlate(e.target.value);
	};

	const handleChange8 = (e) => {
		setChosenService(e.target.value);
	};

	const getUserCoordinates = (position) => {
		setLatitude(position.coords.latitude);
		setLongitude(position.coords.longitude);
		return position;
	};

	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				getUserCoordinates,
				handleGeoLocationError,
			);
			setButtonClicked(true);
			ReactGA.event({
				category: "Client Asked For Roadside Assistance",
				action: "Client Aksed For Roadside Assistance in the spanish page",
				label: "The chosen Service Is" + chosenService,
			});
		} else {
			console.log("Geolocation is not supported");
		}
	};

	const handleGeoLocationError = (error) => {
		switch (error.code) {
			case error.PERMISSION_DENIED:
				alert("You have denied the request for your Geolocation.");
				break;
			case error.POSITION_UNAVAILABLE:
				alert("Location information is unavailable, Please try again later.");
				break;
			case error.TIMEOUT:
				alert("The request has timed out.");
				break;
			case error.UNKNOWN_ERROR:
				alert("An unknown error occurred, Please try again later.");
				break;
			default:
				alert("An unknown error occurred, Please try again later.");
		}
	};

	const getAdress = () => {
		if (latitude && longitude) {
			fetch(
				`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`,
			)
				.then((response) => response.json())
				.then((data) => {
					setUserAddress(data.results[0].formatted_address);
					setUserAddress2(data.results[1].formatted_address);
					setUserAddress3(data.results[2].formatted_address);
					setUserAddress4(data.results[3].formatted_address);
					setUserAddress5(data.results[4].formatted_address);
					setUserAddress6(data.results[5].formatted_address);
					console.log(data);
					///////////Creating Calling Order

					createCallingOrder({
						fullName,
						phoneNumber,
						longitude,
						latitude,
						chosenService,
						locationAddress,
						licensePlate,
						carMake: make,
						carModel: model,
						carColor: color,
						useAddress: data.results[0].formatted_address,
						useAddress2: data.results[1].formatted_address,
						useAddress3: data.results[2].formatted_address,
						useAddress4: data.results[3].formatted_address,
						useAddress5: data.results[4].formatted_address,
						useAddress6: data.results[5].formatted_address,
					}).then((data2) => {
						if (data2.error) {
							console.log("error creating calling order");
						} else {
							toast.success(
								"Your Location Was Successfully Captured Please Call 9094019583",
							);
						}
					});
				})
				.catch((error) => console.log(error, "error"));
		} else {
			return null;
		}
	};

	useEffect(() => {
		getAdress();
		// eslint-disable-next-line
	}, [longitude, latitude, buttonClicked]);

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	return (
		<OrderingPageWrapper>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Palacios Towing | Llámenos Formulario</title>
				<meta
					name='description'
					content='Servicios de Grúa Palacios. Respuesta rápida y atención de calidad. Llámanos si necesitas asistencia en carretera. Una muestra de nuestros servicios Jump Start, Lock Out, Cambio de llantas, Servicio de gasolina y Servicios de remolque
					¡Nosotros hablamos español!
					Infinite-Apps... ¡La elección correcta para desarrollar su aplicación web!
					Este sitio web es para empresas de remolque creadas por Infinite Apps  http://infinite-apps.com
					Ponte en marcha en 1 a 3 días con tu propio nombre de dominio y DEJA de promocionar a otros.
					En infinite apps siempre tratamos de ayudar a las empresas a crecer y desarrollarse, especialmente aquellas que dependen en gran medida de la programación de citas o reuniones, como peluquerías, peluquerías, peluquerías, salones de uñas, etc.
					En infinite apps también desarrollamos aplicaciones web de comercio electrónico para ayudarlo a presentar u ofrecer fácilmente sus productos sin problemas o incluso sin ser utilizados. Eche un vistazo a las ofertas de Infinite Apps y quedará absolutamente impresionado con nuestros precios y servicios.
					Salones de aseo o Groomers: infinitas aplicaciones ofrecen nombre de dominio, alojamiento privado de Linux, panel de administración, panel de Groomer, programador de tienda de aseo, panel de usuario, sistema de SMS, sistema de inicio de sesión/registro y muchas otras características.
					Peluquerías: infinitas aplicaciones ofrecen nombre de dominio, alojamiento privado de Linux, panel de administración, panel de estilista, programador de tiendas, panel de usuario, sistema de SMS, sistema de inicio de sesión/registro y muchas otras funciones más.
					Peluquerías o barberos: las aplicaciones infinitas ofrecen nombre de dominio, alojamiento privado de Linux, panel de administración, panel de estilista, programador de tiendas, panel de usuario, sistema de SMS, sistema de inicio de sesión/registro y muchas otras funciones más.'
				/>
				<link rel='canonical' href='https://palacios-towing.com/calling' />
			</Helmet>
			<h2>Servicios De Palacios Towing</h2>

			<div className='container my-5'>
				<div className='row'>
					<div className='form-group col-md-4 mx-auto'>
						<label className='text-muted'>
							Nombre Completo{" "}
							{fullName.length >= 2 ? null : (
								<span className='requiredOptional'>(Requerido)</span>
							)}{" "}
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange1}
							value={fullName}
							required
							placeholder='e.g. John Don'
						/>
					</div>

					<div className='form-group col-md-4 mx-auto'>
						<label className='text-muted'>
							Número De Teléfono{" "}
							{phoneNumber.length >= 5 ? null : (
								<span className='requiredOptional'>(Requerido)</span>
							)}{" "}
						</label>
						<input
							type='number'
							className='form-control'
							onChange={handleChange2}
							value={phoneNumber}
							required
							placeholder='Solo Numeros, e.g. 9094019583'
						/>
					</div>
					<div className='form-group col-md-3 mx-auto'>
						<label className='text-muted'>
							License Plate # / Placa #{" "}
							<span className='Optional'>(Opcional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange7}
							value={licensePlate}
							required
							placeholder='e.g. License Plate'
						/>
					</div>

					<div className='form-group col-md-3 mx-auto'>
						<label className='text-muted'>
							Marca De Auto <span className='Optional'>(Opcional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange4}
							value={make}
							required
							placeholder='e.g. Honda'
						/>
					</div>

					<div className='form-group col-md-3 mx-auto'>
						<label className='text-muted'>
							Modelo De Auto <span className='Optional'>(Opcional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange5}
							value={model}
							required
							placeholder='e.g. Civic'
						/>
					</div>

					<div className='form-group col-md-3 mx-auto'>
						<label className='text-muted'>
							Color Del Coche <span className='Optional'>(Opcional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange6}
							value={color}
							required
							placeholder='e.g. Azul'
						/>
					</div>

					<div className='form-group col-md-8 mx-auto'>
						<label className='text-muted'>
							Por favor complete el servicio requerido{" "}
							{chosenService.length >= 2 ? null : (
								<span className='requiredOptional'>(Requerido)</span>
							)}{" "}
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange8}
							value={chosenService}
							required
							placeholder='e.g. Remolque, Buen inicio, Cambio de llanta, Servicio de bloqueo, etc...'
						/>
					</div>

					<div className='form-group col-md-8 mx-auto mt-4'>
						<label className='text-muted'>
							Si conoce su ubicación actual, complételo{" "}
							<span className='Optional'>(Opcional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange3}
							value={locationAddress}
							required
							placeholder='Opcional* Si conoce su ubicación exacta'
						/>
					</div>
					{buttonClicked && latitude && longitude ? (
						<Fragment>
							<div className='form-group col-md-8  mx-5'>
								<label className='text-muted'>
									1. Estimación de Google de su ubicación actual :
								</label>{" "}
								<span style={{ fontSize: "12px", fontWeight: "bold" }}>
									{useAddress}{" "}
									<span
										style={{
											color: "green",
											fontSize: "14px",
											fontWeight: "bold",
										}}>
										(Precisión 98%)
									</span>
								</span>
							</div>
							<div className='form-group col-md-8  mx-5'>
								<label className='text-muted'>
									2. Estimación de Google de su ubicación actual :
								</label>{" "}
								<span style={{ fontSize: "12px", fontWeight: "bold" }}>
									{useAddress2}{" "}
									<span
										style={{
											color: "green",
											fontSize: "14px",
											fontWeight: "bold",
										}}>
										(Precisión 90%)
									</span>{" "}
								</span>
							</div>
							<div className='form-group col-md-8  mx-5'>
								<label className='text-muted'>
									3. Estimación de Google de su ubicación actual :
								</label>{" "}
								<span style={{ fontSize: "12px", fontWeight: "bold" }}>
									{useAddress3}{" "}
									<span
										style={{
											color: "green",
											fontSize: "14px",
											fontWeight: "bold",
										}}>
										(Precisión 85%)
									</span>
								</span>
							</div>
						</Fragment>
					) : null}

					<div className='my-5 col-md-12 text-center'>
						{!buttonClicked ? (
							<button
								disabled={
									!fullName ||
									phoneNumber.length < 4 ||
									chosenService.length < 3
								}
								className='btn btn-primary'
								onClick={getLocation}>
								Haga clic aquí para notificar a un conductor de remolque para
								ayudar a su
							</button>
						) : (
							<button
								onClick={() => window.open("tel:9094019583")}
								className='btn btn-info btn-block col-md-6 mx-auto  '>
								Por favor llámenos al 9094019583
							</button>
						)}
					</div>
					<div className='my-5 col-md-12 mx-auto text-center'>
						{latitude && longitude ? (
							<div>
								<img src={img_url_close} alt='GoogleMaps' />
							</div>
						) : null}
					</div>
					<div className='mb-2 col-md-12 mx-auto text-center'>
						{latitude && longitude ? (
							<div>
								<img src={img_url_far} alt='GoogleMaps' />
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div className='mt-5'>
				<PowerBySnippet />
			</div>
		</OrderingPageWrapper>
	);
};

export default OrderingPageSpanish;

const OrderingPageWrapper = styled.div`
	margin-bottom: 100px;

	h2 {
		text-align: center;
		margin: 50px 0px;
		letter-spacing: 3px;
		font-weight: bold;
	}

	.container {
		border: 3px solid darkgoldenrod;
		padding: 50px 0px;
		border-radius: 20px;
	}

	.requiredOptional {
		color: red;
		font-weight: bolder;
		font-size: 0.8rem;
	}

	.Optional {
		color: darkgoldenrod;
		font-weight: bolder;
		font-size: 0.8rem;
	}

	@media (max-width: 1025px) {
		.form-group {
			margin: 0px 20px 40px 20px !important;
		}
		h2 {
			font-size: 1.5rem;
		}
	}
`;
