/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
// import AboutPhoto from "../imgs/traffic-3098747_1920.jpg";
import AboutPhoto from "../imgs/JesusTruck.jpg";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import PowerBySnippet from "./PowerBySnippet";

const About = () => {
	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	return (
		<AboutPageWrapper>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Palacios Towing | About</title>
				<meta
					name='description'
					content=' Palacios Towing Services. Fast Response And Quality Care. Please call us if you need any roadside Assistance. A Sample of our services Jump Start, Lock Out, Tire Change, Gasoline Service and Towing Services
					We Speak Spanish!
					Infinite-Apps... The Right Choice To Develop Your Web Application!
					This website is for Towing Businesses created by Infinite Apps / http://infinite-apps.com
					Get up and running in 1 to 3 days with your own domain name and STOP promoting others.
					We at infinite apps always trying to help businesses to grow and develop specially businesses that primarily highly dependent on scheduling appointments or meetings such as, Barber Shops, Hair Salons, Grooming Salons, Nail Salons, etc...
					We at infinite apps develop eCommerce Web Apps as well to help you easily present or offer your products without any hassel or even without being used. Take a look at Infinite Apps offers and you will absolutely be impressed with our pricing and services.
					Grooming Salons or Groomers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Groomer Dashboard, Grooming Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Hair Salons: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Barber Shops or Barbers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.'
				/>
				<link rel='canonical' href='https://palacios-towing.com/about' />
			</Helmet>
			<div className='container my-5'>
				<h1 className='title text-center '>ABOUT US</h1>
				<div className='col-md-5 mx-auto mb-5'>
					<br />
					<div className='horizLine'></div>
				</div>
				<div className='row'>
					<div className='col-md-6 about-us'>
						<p className='about-title'>Why we're different</p>
						<ul>
							<li>
								As a family owned and operated business started in 2021, we at
								Palacios Towing are dedicated to those experiencing an
								unfortunate breakdown and need their vehicle back on the road
								quickly. We pride ourselves in being available 24/7 while being
								fast, reliable, honest and affordable. Our trucks offer
								Emergency Towing Service, Jump Starts, Lock Outs, Tire Changes
								and Gasoline Services in San Bernardino and Riverside counties.
								We look forward to serving you and handling all of your towing
								needs.
							</li>
						</ul>
					</div>
					<div className='col-md-6 imgdiv'>
						<img src={AboutPhoto} className='img-fluid' alt='PalacioTowing' />
					</div>
				</div>
			</div>
			<div className='mt-5'>
				<PowerBySnippet />
			</div>
		</AboutPageWrapper>
	);
};

export default About;

const AboutPageWrapper = styled.section`
	background: #f8f9fa;
	padding-bottom: 50px;
	padding-top: 50px;

	ul {
		list-style: none;
	}

	.about-title {
		font-size: 40px;
		font-weight: 600;
		margin-top: 8%;
	}
	#about-us ul li {
		margin: 10px 0;
	}

	#about-us ul {
		margin-left: 20px;
	}

	.imgdiv {
		transform: rotate(-8deg);
		box-shadow: 3px 10px 3px 10px rgba(0, 0, 0, 0.1);
	}

	.horizLine {
		border-bottom: #2f2727 solid 5px;
	}
`;
