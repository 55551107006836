/** @format */
import React, { useState, useEffect } from "react";
// import ReactGA from "react-ga";
// import { Link } from "react-router-dom";
import { Button } from "antd";
import styled from "styled-components";
import { getAllOrders } from "./apiAdmin";
import { isAuthenticated } from "../auth/index";
// import ScheduleCalendar from "./ScheduleCalendar";
import Adminsidebar from "./AdminSideBar/Adminsidebar";
import DarkBG from "./AdminSideBar/DarkBG";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import ReactExport from "react-export-excel";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const AdminDashboard = () => {
	const [click2, setClick2] = useState(false);
	const [clickMenu2, setClickMenu2] = useState(false);
	const [allOrders, setAllOrders] = useState(false);
	const [q, setQ] = useState("");
	const [excelDataSet, setExcelDataSet] = useState([]);

	const { user, token } = isAuthenticated();

	useEffect(() => {
		setClickMenu2(click2);
	}, [click2, clickMenu2]);

	const loadingAllOrders = () => {
		function compareTotalAppointments(a, b) {
			const TotalAppointmentsA = a.createdAt;
			const TotalAppointmentsB = b.createdAt;

			let comparison = 0;
			if (TotalAppointmentsA < TotalAppointmentsB) {
				comparison = 1;
			} else if (TotalAppointmentsA > TotalAppointmentsB) {
				comparison = -1;
			}
			return comparison;
		}
		getAllOrders(token, user._id).then((data) => {
			if (data.error) {
				console.log(data.error);
			} else {
				setAllOrders(data.sort(compareTotalAppointments));

				setExcelDataSet(
					data.sort(compareTotalAppointments) &&
						data.sort(compareTotalAppointments).map((data, i) => {
							return {
								id: i + 1,
								OrderedOn: new Date(data.createdAt).toLocaleString(),
								ClientName: data.fullName,
								ClientPhone: data.phoneNumber,
								CustomerFilledInLocation: data.locationAddress,
								carMake: data.carMake,
								carColor: data.carColor,
								carModel: data.carModel,
								longitude: data.longitude,
								latitude: data.latitude,
								googleEstimatedLocation_1: data.useAddress,
								googleEstimatedLocation_2: data.useAddress2,
								googleEstimatedLocation_3: data.useAddress3,
								googleEstimatedLocation_4: data.useAddress4,
								googleEstimatedLocation_5: data.useAddress5,
								googleEstimatedLocation_6: data.useAddress6,
							};
						}),
				);
			}
		});
	};

	useEffect(() => {
		loadingAllOrders();
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	const DownloadExcel = () => {
		return (
			<ExcelFile
				filename={`Overall Orders ${new Date().toLocaleString()}`}
				element={
					<Button
						bsStyle='info'
						style={{
							backgroundColor: "black",
							color: "white",
							borderRadius: "10px",
							marginLeft: "10%",
							padding: "10px",
						}}>
						{" "}
						Download Report{" "}
					</Button>
				}>
				<ExcelSheet data={excelDataSet} name='Appointments'>
					<ExcelColumn label='Id' value='id' />
					<ExcelColumn label='Ordered On' value='OrderedOn' />
					<ExcelColumn label='Client Name' value='ClientName' />
					<ExcelColumn
						label='Location Filled By Client'
						value='CustomerFilledInLocation'
					/>
					<ExcelColumn label='Car Make' value='carMake' />

					<ExcelColumn label='Car Model' value='carModel' />
					<ExcelColumn label='Car Color' value='carColor' />
					<ExcelColumn label='Longitude' value='longitude' />
					<ExcelColumn label='Latitude' value='latitude' />
					<ExcelColumn
						label='Google Captured Loc. 1'
						value='googleEstimatedLocation_1'
					/>
					<ExcelColumn
						label='Google Captured Loc. 2'
						value='googleEstimatedLocation_2'
					/>
					<ExcelColumn
						label='Google Captured Loc. 3'
						value='googleEstimatedLocation_3'
					/>
					<ExcelColumn
						label='Google Captured Loc. 4'
						value='googleEstimatedLocation_4'
					/>
					<ExcelColumn
						label='Google Captured Loc. 5'
						value='googleEstimatedLocation_5'
					/>
					<ExcelColumn
						label='Google Captured Loc. 6'
						value='googleEstimatedLocation_6'
					/>
				</ExcelSheet>
			</ExcelFile>
		);
	};

	function search(orders) {
		return orders.filter((row) => {
			var datesYaba = new Date(row.createdAt).toLocaleDateString();
			return (
				(row.phoneNumber &&
					row.phoneNumber.toString().toLowerCase().indexOf(q) > -1) ||
				(datesYaba && datesYaba.toString().toLowerCase().indexOf(q) > -1) ||
				(row.fullName &&
					row.fullName.toString().toLowerCase().indexOf(q) > -1) ||
				row.useAddress.toString().toLowerCase().indexOf(q) > -1 ||
				row.useAddress2.toString().toLowerCase().indexOf(q) > -1 ||
				row.useAddress3.toString().toLowerCase().indexOf(q) > -1 ||
				row.useAddress4.toString().toLowerCase().indexOf(q) > -1 ||
				(row.locationAddress &&
					row.locationAddress.toString().toLowerCase().indexOf(q) > -1) ||
				(row.carMake && row.carMake.toString().toLowerCase().indexOf(q) > -1) ||
				(row.carColor &&
					row.carColor.toString().toLowerCase().indexOf(q) > -1) ||
				(row.carModel &&
					row.carModel.toString().toLowerCase().indexOf(q) > -1) ||
				// row.BookedFrom.toString().toLowerCase().indexOf(q) > -1 ||
				row.useAddress5.toString().toLowerCase().indexOf(q) > -1 ||
				row.useAddress6.toString().toLowerCase().indexOf(q) > -1
			);
		});
	}

	const allLoadedOrders = () => {
		return (
			<Summary>
				<div className=' mb-3 form-group mx-3 text-center'>
					<label
						className='mt-3 mx-3'
						style={{
							fontWeight: "bold",
							fontSize: "1.05rem",
							color: "black",
							borderRadius: "20px",
						}}>
						Search
					</label>
					<input
						className='p-2 my-5 '
						type='text'
						value={q}
						onChange={(e) => setQ(e.target.value.toLowerCase())}
						placeholder='Search By Client Phone, Client Name, Order Date, Car Specs or Address'
						style={{ borderRadius: "20px", width: "50%" }}
					/>
				</div>
				<div className='my-3'>{DownloadExcel()}</div>
				<table
					className='table table-bordered table-md-responsive table-hover table-striped'
					style={{ fontSize: "0.75rem" }}>
					<thead className='thead-light'>
						<tr>
							<th scope='col'>#</th>
							<th scope='col'>Created At</th>
							<th scope='col'>Client Name</th>
							<th scope='col'>Client Phone Number</th>
							<th scope='col' style={{ fontWeight: "bold", width: "10%" }}>
								Customer Filled In Location
							</th>
							<th scope='col'>Car Make</th>
							<th scope='col'>Car Model</th>
							<th scope='col'>Car Color</th>
							<th scope='col'>chosen Service</th>
							<th scope='col'>Longitude</th>
							<th scope='col'>Latitude</th>
							<th scope='col'>Google Est. Location 1</th>
							<th scope='col'>Google Est. Location 2</th>
							<th scope='col'>Google Est. Location 3</th>
							<th scope='col'>Google Est. Location 4</th>
						</tr>
					</thead>

					<tbody>
						{allOrders &&
							search(allOrders).map((s, i) => (
								<tr key={i}>
									<td>{i + 1}</td>
									<td>
										{new Date(s.createdAt).toLocaleDateString()}{" "}
										{new Date(s.createdAt).toLocaleTimeString()}{" "}
									</td>

									<td>{s.fullName}</td>
									<td>{s.phoneNumber}</td>
									<td
										style={{
											fontWeight: "bold",
											color: "green",
											fontSize: "15px",
										}}>
										{s.locationAddress}
									</td>
									<td>{s.carMake}</td>
									<td>{s.carModel}</td>
									<td>{s.carColor}</td>
									<td>{s.chosenService}</td>
									<td>{s.longitude}</td>
									<td>{s.latitude}</td>
									<td>{s.useAddress}</td>
									<td>{s.useAddress2}</td>
									<td>{s.useAddress3}</td>
									<td>{s.useAddress4}</td>
								</tr>
							))}
					</tbody>
				</table>
			</Summary>
		);
	};

	return (
		<AdminDashboardWrapper>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Infinite-Apps - Our Services</title>
				<meta
					name='description'
					content='Infinite-Apps... The Right Choice To Develop Your Web Application!
					This is a demo website for Towing Businesses created by Infinite Apps / http://infinite-apps.com
					Get up and running in 1 to 3 days with your own domain name and STOP promoting others.
					We at infinite apps always trying to help businesses to grow and develop specially businesses that primarily highly dependent on scheduling appointments or meetings such as, Barber Shops, Hair Salons, Grooming Salons, Nail Salons, etc...
					Our Services at Infinite Apps:

					Grooming Salons or Groomers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Groomer Dashboard, Grooming Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Hair Salons: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Barber Shops or Barbers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.

					E-Commerce Apps or E-commerce Webshops: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Cart Page, Client Payment, User Dashboard, SMS System, Login/Registering System and many more other features.
					Infinite Apps Team Wants to Help. Our marketing plans are unbeatable for your business.

					Other Services: Clinics, Small Towing Businesses, Rental Companies, Restaurants, Private Tutors, Nail Salons, Massage Salons, and many more.
					'
				/>
				<link rel='canonical' href='http://infinite-apps.com' />
			</Helmet>
			{click2 && clickMenu2 ? (
				<DarkBG setClick2={setClick2} setClickMenu2={setClickMenu2} />
			) : null}
			<div className='mx-auto'>
				<Adminsidebar
					click2={click2}
					setClick2={setClick2}
					clickMenu2={clickMenu2}
					setClickMenu2={setClickMenu2}
				/>
			</div>
			{allLoadedOrders()}
		</AdminDashboardWrapper>
	);
};

export default AdminDashboard;

const AdminDashboardWrapper = styled.div`
	margin-bottom: 200px;
`;

const Summary = styled.div`
	margin-right: 20px;
	margin-left: 20px;
	overflow-x: auto;
	@media (max-width: 1100px) {
		font-size: 0.5rem;
		margin-right: 5px;
		margin-left: 5px;
	}
`;
