/** @format */

export const getServices = (token) => {
	return fetch(`${process.env.REACT_APP_API_URL}/services`, {
		method: "GET",
		headers: {
			// content type?
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${token}`,
		},
	})
		.then((response) => {
			return response.json();
		})
		.catch((err) => console.log(err));
};

export const createCallingOrder = (callingOrder) => {
	return fetch(`${process.env.REACT_APP_API_URL}/calling-order-create`, {
		method: "POST",
		headers: {
			Accept: "application/json",
			"Content-Type": "application/json",
			// Authorization: `Bearer ${token}`,
		},
		body: JSON.stringify(callingOrder),
	})
		.then((response) => {
			return response.json();
		})
		.catch((err) => {
			console.log(err);
		});
};
