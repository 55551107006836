/** @format */
import React, { useState, useEffect, Fragment } from "react";
import { updateService, getServices, cloudinaryUpload1 } from "./apiAdmin";
import { isAuthenticated } from "../auth/index";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Adminsidebar from "./AdminSideBar/Adminsidebar";
import DarkBG from "./AdminSideBar/DarkBG";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const UpdateService = ({ match }) => {
	const [click2, setClick2] = useState(false);
	const [clickMenu2, setClickMenu2] = useState(false);

	useEffect(() => {
		setClickMenu2(click2);
	}, [click2, clickMenu2]);

	const [allServices, setAllServices] = useState([]);
	const { user, token } = isAuthenticated();
	const [selectedService, setSelectedService] = useState([]);
	const [servicePrice, setServicePrice] = useState("");
	const [servicePriceDiscount, setServicePriceDiscount] = useState("");
	const [serviceName, setServiceName] = useState("");
	const [serviceName_Spanish, setServiceName_Spanish] = useState("");
	const [customerType, setCustomerType] = useState("");
	const [customerType_Spanish, setCustomerType_Spanish] = useState("");
	const [serviceTime, setServiceTime] = useState("");
	const [activeService, setActiveService] = useState("1");
	const [serviceLoyaltyPoints, setServiceLoyaltyPoints] = useState(0);
	const [linkClick, setLinkClick] = useState(false);
	const [loading, setLoading] = useState(false);
	const [serviceDescription, setServiceDescription] = useState("");
	const [serviceDescriptionCombined, setServiceDescriptionCombined] = useState(
		[],
	);
	const [serviceDescription_Spanish, setServiceDescription_Spanish] =
		useState("");
	const [
		serviceDescriptionCombined_Spanish,
		setServiceDescriptionCombined_Spanish,
	] = useState([]);

	const [imageDeletedFlag1, setImageDeletedFlag1] = useState(false);

	const [unitOfMeasureText, setUnitOfMeasureText] = useState("");
	const [miles_KM, setMiles_KM] = useState(0);
	const [addThumbnail, setAddThumbnail] = useState([]);

	const gettingAllServices = () => {
		getServices(token).then((data) => {
			if (data.error) {
				console.log(data.error);
			} else {
				setAllServices(data);
				setSelectedService(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId),
				);
				setServiceName(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0].serviceName,
				);
				setServiceName_Spanish(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0]
							.serviceName_Spanish,
				);
				setCustomerType(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0]
							.customerType,
				);
				setCustomerType_Spanish(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0]
							.customerType_Spanish,
				);

				setServicePrice(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0]
							.servicePrice,
				);
				setServicePriceDiscount(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0]
							.servicePriceDiscount,
				);
				setServiceTime(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0].serviceTime,
				);
				setServiceLoyaltyPoints(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0]
							.serviceLoyaltyPoints,
				);
				setServiceDescriptionCombined(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0]
							.serviceDescription,
				);

				setServiceDescriptionCombined_Spanish(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0]
							.serviceDescription_Spanish,
				);

				setUnitOfMeasureText(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0]
							.unitOfMeasureText,
				);

				setMiles_KM(
					match.params.serviceId &&
						match.params.serviceId !== "undefined" &&
						data.filter((s) => s._id === match.params.serviceId)[0].miles_KM,
				);
			}
		});
	};

	useEffect(() => {
		gettingAllServices();
		// eslint-disable-next-line
	}, [match.params.serviceId, loading]);

	// const handleChange1 = (e) => {
	// 	setServiceName(e.target.value);
	// };
	const handleChange2 = (e) => {
		setServicePrice(e.target.value);
	};

	const handleChange3 = (e) => {
		setServiceTime(e.target.value);
	};

	// eslint-disable-next-line
	const handleChange4 = (e) => {
		setServiceLoyaltyPoints(e.target.value);
	};

	const handleChange5 = (e) => {
		setActiveService(e.target.value);
	};

	const handleChange8 = (e) => {
		setServiceDescription(e.target.value);
	};

	const handleChange9 = (e) => {
		setServicePriceDiscount(e.target.value);
	};

	const handleChange10 = (e) => {
		setUnitOfMeasureText(e.target.value);
	};

	const handleChange11 = (e) => {
		setMiles_KM(e.target.value);
	};

	const handleChange12 = (e) => {
		setServiceName_Spanish(e.target.value);
	};

	// eslint-disable-next-line
	const handleChange13 = (e) => {
		setCustomerType_Spanish(e.target.value);
	};

	const handleChange14 = (e) => {
		setServiceDescription_Spanish(e.target.value);
	};

	const pushToServiceDescription = (e) => {
		e.preventDefault();
		setServiceDescriptionCombined([
			...serviceDescriptionCombined,
			serviceDescription,
		]);
		setServiceDescription("");
	};

	const pushToServiceDescription_Spanish = (e) => {
		e.preventDefault();
		setServiceDescriptionCombined_Spanish([
			...serviceDescriptionCombined_Spanish,
			serviceDescription_Spanish,
		]);
		setServiceDescription("");
	};

	const clickSubmit = (e) => {
		e.preventDefault();
		setLoading(true);
		if (activeService === "0") {
			if (
				window.confirm(
					"Are you sure you want to deactivate the selected Service?",
				)
			) {
				updateService(match.params.serviceId, user._id, token, {
					serviceName,
					serviceName_Spanish,
					customerType,
					customerType_Spanish,
					servicePrice,
					servicePriceDiscount,
					unitOfMeasureText,
					miles_KM,
					serviceTime,
					serviceLoyaltyPoints: 0,
					activeService,
					serviceDescription: serviceDescriptionCombined,
					serviceDescription_Spanish: serviceDescriptionCombined_Spanish,
					serviceType: selectedService[0].serviceType,
					thumbnail:
						addThumbnail && addThumbnail.images !== undefined
							? addThumbnail && addThumbnail.images
							: selectedService &&
							  selectedService.length > 0 &&
							  selectedService[0].thumbnail,
				}).then((data) => {
					if (data.error) {
						console.log(data.error);
						setLoading(false);
					} else {
						toast.success("Service was successfully Updated.");
						setTimeout(function () {
							setLinkClick(false);
							setLoading(false);
						}, 2000);
					}
				});
			}
		} else {
			updateService(match.params.serviceId, user._id, token, {
				serviceName,
				serviceName_Spanish,
				customerType,
				customerType_Spanish,
				servicePrice,
				servicePriceDiscount,
				unitOfMeasureText,
				miles_KM,
				serviceTime,
				serviceLoyaltyPoints,
				activeService,
				serviceDescription: serviceDescriptionCombined,
				serviceDescription_Spanish: serviceDescriptionCombined_Spanish,
				serviceType: selectedService[0].serviceType,
				thumbnail:
					addThumbnail && addThumbnail.images !== undefined
						? addThumbnail && addThumbnail.images
						: selectedService &&
						  selectedService.length > 0 &&
						  selectedService[0].thumbnail,
			}).then((data) => {
				if (data.error) {
					console.log(data.error);
					setLoading(false);
				} else {
					toast.success("Service was successfully Updated.");
					setTimeout(function () {
						setLinkClick(false);
						setLoading(false);
					}, 2000);
				}
			});
		}
	};

	const fileUploadAndResizeThumbNail = (e) => {
		// console.log(e.target.files);
		let files = e.target.files;
		let allUploadedFiles = addThumbnail;
		if (files) {
			for (let i = 0; i < files.length; i++) {
				Resizer.imageFileResizer(
					files[i],
					720,
					720,
					"JPEG",
					100,
					0,
					(uri) => {
						cloudinaryUpload1(user._id, token, { image: uri })
							.then((data) => {
								allUploadedFiles.push(data);

								setAddThumbnail({ ...addThumbnail, images: allUploadedFiles });
							})
							.catch((err) => {
								console.log("CLOUDINARY UPLOAD ERR", err);
							});
					},
					"base64",
				);
			}
		}
	};

	const FileUploadThumbnail = () => {
		return (
			<>
				<label
					className='btn btn-info btn-raised'
					style={{ cursor: "pointer", fontSize: "0.85rem" }}>
					Update Category Thumbnail
					<input
						type='file'
						hidden
						accept='images/*'
						onChange={fileUploadAndResizeThumbNail}
					/>
				</label>
			</>
		);
	};
	// console.log(addThumbnail);

	const handleImageRemove = (public_id) => {
		setLoading(true);
		// console.log("remove image", public_id);
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/admin/removeimage/${user._id}`,
				{ public_id },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((res) => {
				setLoading(false);
				// eslint-disable-next-line
				const { images } = addThumbnail;
				// let filteredImages = images.filter((item) => {
				// 	return item.public_id !== public_id;
				// });
				setAddThumbnail([]);
			})
			.catch((err) => {
				console.log(err);
				setLoading(false);
				setTimeout(function () {
					window.location.reload(false);
				}, 1000);
			});
	};

	console.log(selectedService, "selected Services");

	return (
		<React.Fragment>
			<ToastContainer />
			{click2 && clickMenu2 ? (
				<DarkBG setClick2={setClick2} setClickMenu2={setClickMenu2} />
			) : null}
			<div className='mx-auto'>
				<Adminsidebar
					click2={click2}
					setClick2={setClick2}
					clickMenu2={clickMenu2}
					setClickMenu2={setClickMenu2}
				/>
			</div>
			<div
				className='container-fluid p-4 mt-3 col-10'
				style={{
					border: "darkBlue solid 1px",
					borderRadius: "20px",
					marginBottom: "215px",
				}}>
				<div className='row'>
					<ul className='list-group col-md-6'>
						<h3 className='text-center mt-5'>
							Total of {allServices.length} Added Services
						</h3>
						<p className='mt-2 text-center'>
							Please Select Which Service You Would Like To Update...
						</p>
						{allServices.map((s, i) => (
							<Link
								to={`/admin/update-service-price/${s._id}`}
								onClick={() => {
									setLinkClick(true);
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}
								key={i}>
								<div
									className='container'
									style={{ textTransform: "capitalize" }}>
									<div className='row'>
										<li
											className='list-group-item d-flex my-1 py-4 justify-content-between align-items-center col-md-6'
											style={{ fontSize: "0.75rem" }}>
											<strong>{s.serviceName}</strong>
											<strong>
												{" "}
												Service For:{" "}
												<span
													style={{ color: "red", textTransform: "capitalize" }}>
													{s.customerType}
												</span>
											</strong>{" "}
										</li>
										<li
											className='list-group-item d-flex my-1 py-4 justify-content-between align-items-center  col-md-2'
											style={{ fontSize: "0.75rem" }}>
											<strong>${s.servicePrice}</strong>,
											<strong style={{ color: "green" }}>
												${s.servicePriceDiscount}
											</strong>
										</li>

										{!s.activeService && (
											<li
												className='list-group-item d-flex my-1 py-4 justify-content-between align-items-center  col-md-3'
												style={{
													fontSize: "0.7rem",
													color: "red",
													fontWeight: "bold",
												}}>
												<strong>Deactivated</strong>
											</li>
										)}
									</div>
								</div>
							</Link>
						))}
					</ul>
					{allServices && selectedService && selectedService[0] && linkClick ? (
						<form
							onSubmit={clickSubmit}
							className='col-md-5 mx-auto mt-5'
							style={{ borderLeft: "1px solid brown" }}>
							<h3
								style={{ fontSize: "1.15rem", fontWeight: "bold" }}
								className='text-center mt-1'>
								The Selected Service is "
								{selectedService &&
									selectedService[0] &&
									selectedService[0].serviceName}
								"
							</h3>
							<div className='m-3 col-8'>
								<div className='col-12'>
									{addThumbnail && addThumbnail.images !== undefined ? (
										<Fragment>
											{addThumbnail.images &&
												addThumbnail.images.map((image) => {
													return (
														<div className='m-3 col-6 '>
															<button
																type='button'
																className='close'
																onClick={() => {
																	handleImageRemove(image.public_id);
																	setAddThumbnail([]);
																}}
																style={{
																	color: "white",
																	background: "black",
																	fontSize: "20px",
																}}
																aria-label='Close'>
																<span aria-hidden='true'>&times;</span>
															</button>
															<img
																src={image.url}
																alt='Img Not Found'
																style={{
																	width: "90px",
																	height: "90px",
																	boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.2)",
																}}
																key={image.public_id}
															/>
														</div>
													);
												})}
										</Fragment>
									) : (
										<Fragment>
											{imageDeletedFlag1 ? null : (
												<div className='m-3 col-6 '>
													<button
														type='button'
														className='close'
														onClick={() => {
															handleImageRemove(
																selectedService &&
																	selectedService.length > 0 &&
																	selectedService[0] &&
																	selectedService[0].thumbnail &&
																	selectedService[0].thumbnail[0].public_id,
															);
															setAddThumbnail([]);
															setImageDeletedFlag1(true);
														}}
														style={{
															color: "white",
															background: "black",
															fontSize: "20px",
														}}
														aria-label='Close'>
														<span aria-hidden='true'>&times;</span>
													</button>

													<img
														src={
															selectedService &&
															selectedService.length > 0 &&
															selectedService[0] &&
															selectedService[0].thumbnail &&
															selectedService[0].thumbnail[0]
																? selectedService[0].thumbnail[0].url
																: null
														}
														alt='Img Not Found'
														style={{
															width: "90px",
															height: "90px",
															boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.2)",
														}}
													/>
												</div>
											)}
										</Fragment>
									)}
								</div>
								{FileUploadThumbnail()}
							</div>

							<div className='form-group mt-5 '>
								<label className='text-muted'>Service Name</label>
								<input
									type='text'
									className='form-control'
									// onChange={handleChange1}
									value={serviceName}
									required
								/>
							</div>
							<div className='form-group '>
								<label className='text-muted'>Service Name (Spanish)</label>
								<input
									type='text'
									className='form-control'
									onChange={handleChange12}
									value={serviceName_Spanish}
									required
									// placeholder='Haircut, Color, Wash, etc...'
								/>
							</div>
							<div className='row'>
								<div className='form-group col-md-6 mx-auto'>
									<label className='text-muted'>Service For</label>
									<input
										type='text'
										className='form-control'
										// onChange={handleChange1}
										value={customerType}
										required
									/>
								</div>
								<div className='form-group col-md-6 mx-auto'>
									<label className='text-muted'>Service For (Spanish)</label>
									<input
										type='text'
										className='form-control'
										// onChange={handleChange1}
										value={customerType_Spanish}
										required
									/>
								</div>
							</div>

							{selectedService &&
							selectedService[0] &&
							selectedService[0].serviceType === "package service" ? (
								<div className='col-md-10 mx-auto'>
									<div>
										Added Descriptions:
										<ul>
											{serviceDescriptionCombined &&
												serviceDescriptionCombined.map((desc, e) => (
													<li
														style={{
															listStyle: "none",
															marginLeft: "20px",
															fontSize: "12px",
															marginTop: "5px",
														}}
														key={e}>
														<button
															type='button'
															onClick={() => {
																var array =
																	serviceDescriptionCombined &&
																	serviceDescriptionCombined.filter(function (
																		s,
																	) {
																		return s !== desc;
																	});
																setServiceDescriptionCombined(array);
															}}
															style={{
																color: "white",
																background: "black",
																fontSize: "15px",
																borderRadius: "15px",
																marginRight: "10px",
															}}
															aria-label='Close'>
															<span aria-hidden='true'>&times;</span>
														</button>
														{desc}
													</li>
												))}
										</ul>
									</div>
									<label className='text-muted'>
										Add set of services connected to{" "}
										<span
											style={{
												color: "blue",
												fontWeight: "bold",
												fontSize: "13px",
											}}>
											"{serviceName}"
										</span>
									</label>
									<input
										type='text'
										className='form-control'
										onChange={handleChange8}
										value={serviceDescription}
									/>
									<div className='row'>
										<button
											style={{ fontSize: "12px" }}
											onClick={pushToServiceDescription}
											className='btn btn-outline-info col-md-5  text-center mx-auto my-2'>
											Add Service Description.
										</button>
										<button
											style={{ fontSize: "12px" }}
											onClick={() => {
												setServiceDescriptionCombined([]);
											}}
											className='btn btn-outline-danger col-md-5  text-center mx-auto my-2'>
											Clear Set Of Descriptions
										</button>
									</div>
								</div>
							) : null}

							{selectedService &&
							selectedService[0] &&
							selectedService[0].serviceType === "package service" ? (
								<div className='col-md-10 mx-auto'>
									<div>
										Added Descriptions:
										<ul>
											{serviceDescriptionCombined_Spanish &&
												serviceDescriptionCombined_Spanish.map((desc, e) => (
													<li
														style={{
															listStyle: "none",
															marginLeft: "20px",
															fontSize: "12px",
															marginTop: "5px",
														}}
														key={e}>
														<button
															type='button'
															onClick={() => {
																var array =
																	serviceDescriptionCombined_Spanish &&
																	serviceDescriptionCombined_Spanish.filter(
																		function (s) {
																			return s !== desc;
																		},
																	);
																setServiceDescriptionCombined_Spanish(array);
															}}
															style={{
																color: "white",
																background: "black",
																fontSize: "15px",
																borderRadius: "15px",
																marginRight: "10px",
															}}
															aria-label='Close'>
															<span aria-hidden='true'>&times;</span>
														</button>
														{desc}
													</li>
												))}
										</ul>
									</div>
									<label className='text-muted'>
										Add set of services connected to{" "}
										<span
											style={{
												color: "blue",
												fontWeight: "bold",
												fontSize: "13px",
											}}>
											"{serviceName_Spanish}"
										</span>
									</label>
									<input
										type='text'
										className='form-control'
										onChange={handleChange14}
										value={serviceDescription_Spanish}
									/>
									<div className='row'>
										<button
											style={{ fontSize: "12px" }}
											onClick={pushToServiceDescription_Spanish}
											className='btn btn-outline-info col-md-5  text-center mx-auto my-2'>
											Add Service Description.
										</button>
										<button
											style={{ fontSize: "12px" }}
											onClick={() => {
												setServiceDescriptionCombined([]);
											}}
											className='btn btn-outline-danger col-md-5  text-center mx-auto my-2'>
											Clear Set Of Descriptions
										</button>
									</div>
								</div>
							) : null}

							<div className='row'>
								<div className='form-group col-md-6'>
									<label className='text-muted'>Service Price</label>
									<input
										type='text'
										className='form-control'
										onChange={handleChange2}
										value={servicePrice}
										required
									/>
								</div>
								<div className='form-group col-md-6'>
									<label className='text-muted'>
										Service Price After Discount
									</label>
									<input
										type='text'
										className='form-control'
										onChange={handleChange9}
										value={servicePriceDiscount}
										required
									/>
								</div>
								<div className='form-group col-md-6 mx-auto'>
									<label className='text-muted'>Price Per</label>
									<select onChange={handleChange11} className='form-control'>
										<option>Please select</option>
										<option value={1}>1</option>
										<option value={2}>2</option>
										<option value={3}>3</option>
										<option value={4}>4</option>
										<option value={5}>5</option>
										<option value={6}>6</option>
										<option value={7}>7</option>
										<option value={8}>8</option>
										<option value={9}>9</option>
										<option value={10}>10</option>
									</select>
								</div>

								<div className='form-group col-md-6 mx-auto'>
									<label className='text-muted'>Unit Of Measure</label>
									<select onChange={handleChange10} className='form-control'>
										<option>Please select</option>
										<option value='mile'>Mile</option>
										<option value='kilometer'>Kilometer</option>
									</select>
								</div>
								<div className='form-group col-md-6'>
									<label className='text-muted'>Service time</label>
									<input
										type='text'
										className='form-control'
										onChange={handleChange3}
										value={serviceTime}
										required
									/>
								</div>
							</div>

							<div className='form-group'>
								<label className='text-muted'>Active Service?</label>
								<select
									onChange={handleChange5}
									className='form-control'
									style={{ fontSize: "0.80rem" }}>
									<option>Please select / Required*</option>
									<option value='0'>Deactivate Service</option>
									<option value='1'>Activate Service</option>
								</select>
							</div>
							<button className='btn btn-outline-primary mb-3'>
								Update Service
							</button>
						</form>
					) : (
						<React.Fragment>
							<div
								style={{
									textAlign: "center",
									fontSize: "1.3rem",
									color: "blueviolet",
									marginTop: "8%",
									marginBottom: "12%",
									marginLeft: "100px",
								}}>
								Please Select a Service So You Can Update it.
							</div>
						</React.Fragment>
					)}
				</div>
			</div>
		</React.Fragment>
	);
};

export default UpdateService;
