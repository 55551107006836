/** @format */

import React, { Fragment, useEffect, useState } from "react";
import { toast } from "react-toastify";
import styled from "styled-components";
import { createCallingOrder } from "../ApiCore";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import PowerBySnippet from "./PowerBySnippet";

const OrderingPage = () => {
	const [fullName, setFullName] = useState("");
	const [buttonClicked, setButtonClicked] = useState(false);
	const [phoneNumber, setPhoneNumber] = useState("");
	const [latitude, setLatitude] = useState("");
	const [longitude, setLongitude] = useState("");
	const [licensePlate, setLicensePlate] = useState("");
	const [useAddress, setUserAddress] = useState("");
	const [useAddress2, setUserAddress2] = useState("");
	const [useAddress3, setUserAddress3] = useState("");
	// eslint-disable-next-line
	const [useAddress4, setUserAddress4] = useState("");
	// eslint-disable-next-line
	const [useAddress5, setUserAddress5] = useState("");
	// eslint-disable-next-line
	const [useAddress6, setUserAddress6] = useState("");
	const [locationAddress, setLocationAddress] = useState("");
	const [make, setMake] = useState("");
	const [model, setModel] = useState("");
	const [color, setColor] = useState("");
	const [chosenService, setChosenService] = useState("");

	var img_url_close = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=15&size=700x300&sensor=false&markers=color:red%7C${latitude},${longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`;

	var img_url_far = `https://maps.googleapis.com/maps/api/staticmap?center=${latitude},${longitude}&zoom=10&size=700x300&sensor=false&markers=color:red%7C${latitude},${longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`;

	const handleChange1 = (e) => {
		setFullName(e.target.value);
	};
	const handleChange2 = (e) => {
		setPhoneNumber(e.target.value);
	};

	const handleChange3 = (e) => {
		setLocationAddress(e.target.value);
	};

	const handleChange4 = (e) => {
		setMake(e.target.value);
	};

	const handleChange5 = (e) => {
		setModel(e.target.value);
	};

	const handleChange6 = (e) => {
		setColor(e.target.value);
	};

	const handleChange7 = (e) => {
		setLicensePlate(e.target.value);
	};

	const handleChange8 = (e) => {
		setChosenService(e.target.value);
	};

	const getUserCoordinates = (position) => {
		setLatitude(position.coords.latitude);
		setLongitude(position.coords.longitude);
		return position;
	};

	const getLocation = () => {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(
				getUserCoordinates,
				handleGeoLocationError,
			);
			setButtonClicked(true);
			ReactGA.event({
				category: "Client Asked For Roadside Assistance",
				action: "Client Aksed For Roadside Assistance in the English page",
				label: "The chosen Service Is" + chosenService,
			});
		} else {
			console.log("Geolocation is not supported");
		}
	};

	const handleGeoLocationError = (error) => {
		switch (error.code) {
			case error.PERMISSION_DENIED:
				alert("You have denied the request for your Geolocation.");
				break;
			case error.POSITION_UNAVAILABLE:
				alert("Location information is unavailable, Please try again later.");
				break;
			case error.TIMEOUT:
				alert("The request has timed out.");
				break;
			case error.UNKNOWN_ERROR:
				alert("An unknown error occurred, Please try again later.");
				break;
			default:
				alert("An unknown error occurred, Please try again later.");
		}
	};

	const getAdress = () => {
		if (latitude && longitude) {
			fetch(
				`https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${process.env.REACT_APP_MAPS_API_KEY}`,
			)
				.then((response) => response.json())
				.then((data) => {
					setUserAddress(data.results[0].formatted_address);
					setUserAddress2(data.results[1].formatted_address);
					setUserAddress3(data.results[2].formatted_address);
					setUserAddress4(data.results[3].formatted_address);
					setUserAddress5(data.results[4].formatted_address);
					setUserAddress6(data.results[5].formatted_address);
					console.log(data);
					///////////Creating Calling Order

					createCallingOrder({
						fullName,
						phoneNumber,
						longitude,
						latitude,
						chosenService,
						locationAddress,
						licensePlate,
						carMake: make,
						carModel: model,
						carColor: color,
						useAddress: data.results[0].formatted_address,
						useAddress2: data.results[1].formatted_address,
						useAddress3: data.results[2].formatted_address,
						useAddress4: data.results[3].formatted_address,
						useAddress5: data.results[4].formatted_address,
						useAddress6: data.results[5].formatted_address,
					}).then((data2) => {
						if (data2.error) {
							console.log("error creating calling order");
						} else {
							toast.success(
								"Your Location Was Successfully Captured Please Call 19094019583",
							);
						}
					});
				})
				.catch((error) => console.log(error, "error"));
		} else {
			return null;
		}
	};

	useEffect(() => {
		getAdress();
		// eslint-disable-next-line
	}, [longitude, latitude, buttonClicked]);

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	return (
		<OrderingPageWrapper>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Palacios Towing | Call us Form</title>
				<meta
					name='description'
					content='Palacios Towing Services. Fast Response And Quality Care. Please call us if you need any roadside Assistance. A Sample of our services Jump Start, Lock Out, Tire Change, Gasoline Service and Towing Services We Speak Spanish!
					Infinite-Apps... The Right Choice To Develop Your Web Application!
					This website is for Towing Businesses created by Infinite Apps / http://infinite-apps.com
					Get up and running in 1 to 3 days with your own domain name and STOP promoting others.
					We at infinite apps always trying to help businesses to grow and develop specially businesses that primarily highly dependent on scheduling appointments or meetings such as, Barber Shops, Hair Salons, Grooming Salons, Nail Salons, etc...
					We at infinite apps develop eCommerce Web Apps as well to help you easily present or offer your products without any hassel or even without being used. Take a look at Infinite Apps offers and you will absolutely be impressed with our pricing and services.
					Grooming Salons or Groomers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Groomer Dashboard, Grooming Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Hair Salons: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Barber Shops or Barbers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.'
				/>
				<link rel='canonical' href='https://palacios-towing.com/calling' />
			</Helmet>
			<h2>Palacios Towing Services</h2>

			<div className='container my-5'>
				<div className='row'>
					<div className='form-group col-md-4 mx-auto'>
						<label className='text-muted'>
							Full Name{" "}
							{fullName.length >= 2 ? null : (
								<span className='requiredOptional'>(Required)</span>
							)}{" "}
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange1}
							value={fullName}
							required
							placeholder='e.g. John Don'
						/>
					</div>

					<div className='form-group col-md-4 mx-auto'>
						<label className='text-muted'>
							Phone Number{" "}
							{phoneNumber.length >= 5 ? null : (
								<span className='requiredOptional'>(Required)</span>
							)}{" "}
						</label>
						<input
							type='number'
							className='form-control'
							onChange={handleChange2}
							value={phoneNumber}
							required
							placeholder='Numbers Only, e.g. 9094019583'
						/>
					</div>
					<div className='form-group col-md-3 mx-auto'>
						<label className='text-muted'>
							License Plate # <span className='Optional'>(Optional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange7}
							value={licensePlate}
							required
							placeholder='e.g. License Plate'
						/>
					</div>

					<div className='form-group col-md-3 mx-auto'>
						<label className='text-muted'>
							Make <span className='Optional'>(Optional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange4}
							value={make}
							required
							placeholder='e.g. Honda'
						/>
					</div>

					<div className='form-group col-md-3 mx-auto'>
						<label className='text-muted'>
							Model <span className='Optional'>(Optional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange5}
							value={model}
							required
							placeholder='e.g. Civic'
						/>
					</div>

					<div className='form-group col-md-3 mx-auto'>
						<label className='text-muted'>
							Color <span className='Optional'>(Optional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange6}
							value={color}
							required
							placeholder='e.g. Blue'
						/>
					</div>

					<div className='form-group col-md-8 mx-auto'>
						<label className='text-muted'>
							Please Fill in the required service{" "}
							{chosenService.length >= 2 ? null : (
								<span className='requiredOptional'>(Required)</span>
							)}{" "}
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange8}
							value={chosenService}
							required
							placeholder='e.g. Towing, Jumpstart, Tire Change, Lock Out Service, etc...'
						/>
					</div>

					<div className='form-group col-md-8 mx-auto mt-4'>
						<label className='text-muted'>
							If you know your current location, Please fill it in{" "}
							<span className='Optional'>(Optional)</span>
						</label>
						<input
							type='text'
							className='form-control'
							onChange={handleChange3}
							value={locationAddress}
							required
							placeholder='Optional* If you know your exact location'
						/>
					</div>
					{buttonClicked && latitude && longitude ? (
						<Fragment>
							<div className='form-group col-md-8  mx-5'>
								<label className='text-muted'>
									1. Google Estimate Of Your Current Location :
								</label>{" "}
								<span style={{ fontSize: "12px", fontWeight: "bold" }}>
									{useAddress}{" "}
									<span
										style={{
											color: "green",
											fontSize: "14px",
											fontWeight: "bold",
										}}>
										(Accuracy 98%)
									</span>
								</span>
							</div>
							<div className='form-group col-md-8  mx-5'>
								<label className='text-muted'>
									2. Google Estimate Of Your Current Location :
								</label>{" "}
								<span style={{ fontSize: "12px", fontWeight: "bold" }}>
									{useAddress2}{" "}
									<span
										style={{
											color: "green",
											fontSize: "14px",
											fontWeight: "bold",
										}}>
										(Accuracy 90%)
									</span>{" "}
								</span>
							</div>
							<div className='form-group col-md-8  mx-5'>
								<label className='text-muted'>
									3. Google Estimate Of Your Current Location :
								</label>{" "}
								<span style={{ fontSize: "12px", fontWeight: "bold" }}>
									{useAddress3}{" "}
									<span
										style={{
											color: "green",
											fontSize: "14px",
											fontWeight: "bold",
										}}>
										(Accuracy 85%)
									</span>
								</span>
							</div>
						</Fragment>
					) : null}

					<div className='my-5 col-md-12 text-center'>
						{!buttonClicked ? (
							<button
								disabled={
									!fullName ||
									phoneNumber.length < 4 ||
									chosenService.length < 3
								}
								className='btn btn-primary'
								onClick={getLocation}>
								Click Here to Notify a Towing Driver To Assist Your
							</button>
						) : (
							<button
								onClick={() => window.open("tel:9094019583")}
								className='btn btn-info btn-block col-md-6 mx-auto  '>
								Please Call Us On 9094019583
							</button>
						)}
					</div>
					<div className='my-5 col-md-12 mx-auto text-center'>
						{latitude && longitude ? (
							<div>
								<img src={img_url_close} alt='GoogleMaps' />
							</div>
						) : null}
					</div>
					<div className='mb-2 col-md-12 mx-auto text-center'>
						{latitude && longitude ? (
							<div>
								<img src={img_url_far} alt='GoogleMaps' />
							</div>
						) : null}
					</div>
				</div>
			</div>
			<div className='mt-5'>
				<PowerBySnippet />
			</div>
		</OrderingPageWrapper>
	);
};

export default OrderingPage;

const OrderingPageWrapper = styled.div`
	margin-bottom: 100px;

	h2 {
		text-align: center;
		margin: 50px 0px;
		letter-spacing: 3px;
		font-weight: bold;
	}

	.container {
		border: 3px solid darkgoldenrod;
		padding: 50px 0px;
		border-radius: 20px;
	}

	.requiredOptional {
		color: red;
		font-weight: bolder;
		font-size: 0.8rem;
	}

	.Optional {
		color: darkgoldenrod;
		font-weight: bolder;
		font-size: 0.8rem;
	}

	@media (max-width: 1025px) {
		.form-group {
			margin: 0px 20px 40px 20px !important;
		}
		h2 {
			font-size: 1.5rem;
		}
	}
`;
