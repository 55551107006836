/** @format */

import React, { useState, Fragment, useEffect } from "react";
import { isAuthenticated } from "../auth/index";
// import { Link } from "react-router-dom";
import { createService, getServices, cloudinaryUpload1 } from "./apiAdmin";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import Adminsidebar from "./AdminSideBar/Adminsidebar";
import DarkBG from "./AdminSideBar/DarkBG";
import axios from "axios";
import Resizer from "react-image-file-resizer";

const AddService = () => {
	const [click2, setClick2] = useState(false);
	const [clickMenu2, setClickMenu2] = useState(false);

	useEffect(() => {
		setClickMenu2(click2);
	}, [click2, clickMenu2]);

	const [serviceName, setServiceName] = useState("");
	const [serviceName_Spanish, setServiceName_Spanish] = useState("");
	const [customerType, setCustomerType] = useState("");
	const [customerType_Spanish, setCustomerType_Spanish] = useState("");
	const [serviceType, setServiceType] = useState("Package Service");
	const [servicePrice, setServicePrice] = useState("");
	const [servicePriceDiscount, setServicePriceDiscount] = useState("");
	const [unitOfMeasureText, setUnitOfMeasureText] = useState("");
	const [miles_KM, setMiles_KM] = useState(0);
	const [serviceTime, setServiceTime] = useState("");
	const [allServices, setAllServices] = useState([]);
	const [serviceDescription, setServiceDescription] = useState("");
	const [serviceDescription_Spanish, setServiceDescription_Spanish] =
		useState("");
	const [serviceDescriptionCombined, setServiceDescriptionCombined] = useState(
		[],
	);

	const [
		serviceDescriptionCombined_Spanish,
		setServiceDescriptionCombined_Spanish,
	] = useState([]);

	const [addThumbnail, setAddThumbnail] = useState([]);

	// eslint-disable-next-line
	const [error, setError] = useState(false);
	const [success, setSuccess] = useState(false);

	// destructure user and token from localstorage
	const { user, token } = isAuthenticated();

	const handleChange1 = (e) => {
		setError("");
		setServiceName(e.target.value);
	};
	const handleChange2 = (e) => {
		setError("");
		setServicePrice(e.target.value);
	};

	const handleChange3 = (e) => {
		setError("");
		setServiceTime(e.target.value);
	};

	const handleChange5 = (e) => {
		setError("");
		setCustomerType(e.target.value);
	};

	// eslint-disable-next-line
	const handleChange7 = (e) => {
		setError("");
		setServiceType("Package Service");
	};

	const handleChange8 = (e) => {
		setError("");
		setServiceDescription(e.target.value);
	};

	const handleChange9 = (e) => {
		setError("");
		setServicePriceDiscount(e.target.value);
	};

	const handleChange10 = (e) => {
		setError("");
		setUnitOfMeasureText(e.target.value);
	};

	const handleChange11 = (e) => {
		setError("");
		setMiles_KM(e.target.value);
	};

	const handleChange12 = (e) => {
		setError("");
		setServiceName_Spanish(e.target.value);
	};

	const handleChange13 = (e) => {
		setError("");
		setCustomerType_Spanish(e.target.value);
	};

	const handleChange14 = (e) => {
		setError("");
		setServiceDescription_Spanish(e.target.value);
	};

	const pushToServiceDescription = (e) => {
		e.preventDefault();
		setServiceDescriptionCombined([
			...serviceDescriptionCombined,
			serviceDescription,
		]);
		setServiceDescription("");
	};

	const pushToServiceDescription_Spanish = (e) => {
		e.preventDefault();
		setServiceDescriptionCombined_Spanish([
			...serviceDescriptionCombined_Spanish,
			serviceDescription_Spanish,
		]);
		setServiceDescription("");
	};

	const gettingAllServices = () => {
		getServices(token).then((data) => {
			if (data.error) {
				setError(data.error);
			} else {
				setError("");
				setAllServices(
					data.map(
						(serviceNames) =>
							serviceNames.serviceName.toLowerCase() +
							serviceNames.customerType.toLowerCase(),
					),
				);
			}
		});
	};

	useEffect(() => {
		gettingAllServices();
		// eslint-disable-next-line
	}, [servicePrice, serviceName]);

	let matchingServiceName =
		allServices.indexOf(
			serviceName.toLowerCase() + customerType.toLowerCase(),
		) !== -1;

	const clickSubmit = (e) => {
		e.preventDefault();
		if (matchingServiceName) {
			return toast.error("This service was added before.");
		}

		setError("");
		setSuccess(false);
		// make request to api to create service
		createService(user._id, token, {
			serviceName,
			serviceName_Spanish,
			customerType,
			customerType_Spanish,
			servicePrice,
			servicePriceDiscount,
			miles_KM,
			unitOfMeasureText,
			serviceTime,
			serviceType,
			serviceDescription: serviceDescriptionCombined,
			serviceDescription_Spanish: serviceDescriptionCombined_Spanish,
			thumbnail: addThumbnail && addThumbnail.images,
		}).then((data) => {
			if (data.error) {
				setError(data.error);
			} else {
				toast.success("Service was successfully Added.");
				setError("");
				setTimeout(function () {
					setServicePrice("");
					setServiceTime("");
					window.location.reload(false);
				}, 2500);
			}
		});
	};

	const fileUploadAndResizeThumbNail = (e) => {
		// console.log(e.target.files);
		let files = e.target.files;
		let allUploadedFiles = addThumbnail;
		if (files) {
			for (let i = 0; i < files.length; i++) {
				Resizer.imageFileResizer(
					files[i],
					720,
					720,
					"JPEG",
					100,
					0,
					(uri) => {
						cloudinaryUpload1(user._id, token, { image: uri })
							.then((data) => {
								allUploadedFiles.push(data);

								setAddThumbnail({ ...addThumbnail, images: allUploadedFiles });
							})
							.catch((err) => {
								console.log("CLOUDINARY UPLOAD ERR", err);
							});
					},
					"base64",
				);
			}
		}
	};

	const FileUploadThumbnail = () => {
		return (
			<>
				<label
					className='btn btn-info btn-raised'
					style={{ cursor: "pointer", fontSize: "0.95rem" }}>
					Add a Category Thumbnail
					<input
						type='file'
						hidden
						accept='images/*'
						onChange={fileUploadAndResizeThumbNail}
						required
					/>
				</label>
			</>
		);
	};

	const handleImageRemove = (public_id) => {
		// console.log("remove image", public_id);
		axios
			.post(
				`${process.env.REACT_APP_API_URL}/admin/removeimage/${user._id}`,
				{ public_id },
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				},
			)
			.then((res) => {
				// eslint-disable-next-line
				const { images } = addThumbnail;
				// let filteredImages = images.filter((item) => {
				// 	return item.public_id !== public_id;
				// });
				setAddThumbnail([]);
				setTimeout(function () {
					window.location.reload(false);
				}, 1000);
			})
			.catch((err) => {
				console.log(err);
				setTimeout(function () {
					window.location.reload(false);
				}, 1000);
			});
	};

	const newServiceForm = () => (
		<form onSubmit={clickSubmit}>
			<div className='row'>
				<div className='form-group col-md-6 mx-auto'>
					<label className='text-muted'>Service For</label>
					<input
						type='text'
						className='form-control'
						onChange={handleChange5}
						value={customerType}
						required
						placeholder='Cars, Trucks, etc...'
					/>
				</div>

				<div className='form-group col-md-6 mx-auto'>
					<label className='text-muted'>Service For (Spanish)</label>
					<input
						type='text'
						className='form-control'
						onChange={handleChange13}
						value={customerType_Spanish}
						required
						placeholder='Cars, Trucks, etc...'
					/>
				</div>

				<div className='form-group col-md-6 mx-auto'>
					<label className='text-muted'>Service Name</label>
					<input
						type='text'
						className='form-control'
						onChange={handleChange1}
						value={serviceName}
						required
						// placeholder='Haircut, Color, Wash, etc...'
					/>
				</div>
				<div className='form-group col-md-6 mx-auto'>
					<label className='text-muted'>Service Name (Spanish)</label>
					<input
						type='text'
						className='form-control'
						onChange={handleChange12}
						value={serviceName_Spanish}
						required
						// placeholder='Haircut, Color, Wash, etc...'
					/>
				</div>

				<div className='form-group col-md-6 mx-auto'>
					<label className='text-muted'>Service Price</label>
					<input
						type='number'
						className='form-control'
						onChange={handleChange2}
						value={servicePrice}
						required
						placeholder='The price should be only a number'
					/>
				</div>
				<div className='form-group col-md-6 mx-auto'>
					<label className='text-muted'>Service Price After Discount</label>
					<input
						type='number'
						className='form-control'
						onChange={handleChange9}
						value={servicePriceDiscount}
						required
						placeholder='The price should be only a number'
					/>
				</div>

				<div className='form-group col-md-6 mx-auto'>
					<label className='text-muted'>Price Per</label>
					<select onChange={handleChange11} className='form-control'>
						<option>Please select</option>
						<option value={1}>1</option>
						<option value={2}>2</option>
						<option value={3}>3</option>
						<option value={4}>4</option>
						<option value={5}>5</option>
						<option value={6}>6</option>
						<option value={7}>7</option>
						<option value={8}>8</option>
						<option value={9}>9</option>
						<option value={10}>10</option>
					</select>
				</div>

				<div className='form-group col-md-6 mx-auto'>
					<label className='text-muted'>Unit Of Measure</label>
					<select onChange={handleChange10} className='form-control'>
						<option>Please select</option>
						<option value='mile'>Mile</option>
						<option value='kilometer'>Kilometer</option>
					</select>
				</div>
			</div>
			<div className='row'>
				<div className='col-md-6 mx-auto'>
					<div>
						{serviceDescriptionCombined &&
							serviceDescriptionCombined.length > 0 && (
								<Fragment>
									Added Descriptions:
									<ul>
										{serviceDescriptionCombined &&
											serviceDescriptionCombined.map((i, e) => (
												<li
													style={{
														listStyle: "none",
														marginLeft: "20px",
														fontSize: "12px",
													}}
													key={e}>
													<button
														type='button'
														onClick={() => {
															var array =
																serviceDescriptionCombined &&
																serviceDescriptionCombined.filter(function (s) {
																	return s !== i;
																});
															setServiceDescriptionCombined(array);
														}}
														style={{
															color: "white",
															background: "black",
															fontSize: "15px",
															borderRadius: "15px",
															marginRight: "10px",
														}}
														aria-label='Close'>
														<span aria-hidden='true'>&times;</span>
													</button>
													{i}
												</li>
											))}
									</ul>
								</Fragment>
							)}
					</div>
					<label className='text-muted'>
						Add set of services connected to{" "}
						<span style={{ color: "blue", fontWeight: "bold" }}>
							"{serviceName}"
						</span>
					</label>
					<input
						type='text'
						className='form-control'
						onChange={handleChange8}
						value={serviceDescription}
					/>
					<div className='row'>
						<button
							style={{ fontSize: "12px" }}
							onClick={pushToServiceDescription}
							className='btn btn-outline-info col-md-5  text-center mx-auto my-2'>
							Add Service Description.
						</button>
						<button
							style={{ fontSize: "12px" }}
							onClick={() => {
								setServiceDescriptionCombined([]);
								setServiceType("Please select / Required*");
							}}
							className='btn btn-outline-danger col-md-5  text-center mx-auto my-2'>
							Clear Set Of Descriptions
						</button>
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='col-md-6 mx-auto'>
					<div>
						{serviceDescriptionCombined_Spanish &&
							serviceDescriptionCombined_Spanish.length > 0 && (
								<Fragment>
									Added Descriptions (Spanish):
									<ul>
										{serviceDescriptionCombined_Spanish &&
											serviceDescriptionCombined_Spanish.map((i, e) => (
												<li
													style={{
														listStyle: "none",
														marginLeft: "20px",
														fontSize: "12px",
													}}
													key={e}>
													<button
														type='button'
														onClick={() => {
															var array =
																serviceDescriptionCombined_Spanish &&
																serviceDescriptionCombined_Spanish.filter(
																	function (s) {
																		return s !== i;
																	},
																);
															setServiceDescriptionCombined_Spanish(array);
														}}
														style={{
															color: "white",
															background: "black",
															fontSize: "15px",
															borderRadius: "15px",
															marginRight: "10px",
														}}
														aria-label='Close'>
														<span aria-hidden='true'>&times;</span>
													</button>
													{i}
												</li>
											))}
									</ul>
								</Fragment>
							)}
					</div>
					<label className='text-muted'>
						Add set of services connected to{" "}
						<span style={{ color: "blue", fontWeight: "bold" }}>
							"{serviceName_Spanish}"
						</span>
					</label>
					<input
						type='text'
						className='form-control'
						onChange={handleChange14}
						value={serviceDescription_Spanish}
					/>
					<div className='row'>
						<button
							style={{ fontSize: "12px" }}
							onClick={pushToServiceDescription_Spanish}
							className='btn btn-outline-info col-md-5  text-center mx-auto my-2'>
							Add Service Description (Spanish).
						</button>
						<button
							style={{ fontSize: "12px" }}
							onClick={() => {
								setServiceDescriptionCombined([]);
								setServiceType("Please select / Required*");
							}}
							className='btn btn-outline-danger col-md-5  text-center mx-auto my-2'>
							Clear Set Of Descriptions
						</button>
					</div>
				</div>
			</div>

			<div className='row'>
				<div className='form-group col-md-6 mx-auto'>
					<label className='text-muted'>Service Estimated Time</label>
					<input
						type='number'
						className='form-control'
						onChange={handleChange3}
						value={serviceTime}
						required
						placeholder='Please add a numerical value (minutes)'
					/>
				</div>
			</div>

			<button className='btn btn-outline-primary mb-3'>Add a Service</button>
		</form>
	);

	// eslint-disable-next-line
	const showSuccess = () => {
		if (success) {
			return <h3 className='text-success'>{serviceName} is created</h3>;
		}
	};

	return (
		<Fragment>
			{click2 && clickMenu2 ? (
				<DarkBG setClick2={setClick2} setClickMenu2={setClickMenu2} />
			) : null}
			<div className='mx-auto'>
				<Adminsidebar
					click2={click2}
					setClick2={setClick2}
					clickMenu2={clickMenu2}
					setClickMenu2={setClickMenu2}
				/>
			</div>
			<div
				className='col-md-6 col-sm-6 offset-md-2 mt-5 mx-auto p-3'
				style={{
					border: "1px black solid",
					borderRadius: "20px",
					marginBottom: "200px",
				}}>
				<h3 className='mt-1 mb-3 text-center'>Add Services</h3>
				<ToastContainer />
				<div className='m-3 col-4'>
					<div className='col-12'>
						{addThumbnail &&
							addThumbnail.images &&
							addThumbnail.images.map((image) => {
								return (
									<div className='m-3 col-6 '>
										<button
											type='button'
											className='close'
											onClick={() => {
												handleImageRemove(image.public_id);
												setAddThumbnail([]);
											}}
											style={{
												color: "white",
												background: "black",
												fontSize: "20px",
											}}
											aria-label='Close'>
											<span aria-hidden='true'>&times;</span>
										</button>
										<img
											src={image.url}
											alt='Img Not Found'
											style={{
												width: "90px",
												height: "90px",
												boxShadow: "1px 1px 1px 1px rgba(0,0,0,0.2)",
											}}
											key={image.public_id}
										/>
									</div>
								);
							})}
					</div>
					{FileUploadThumbnail()}
				</div>
				{newServiceForm()}
			</div>
		</Fragment>
	);
};

export default AddService;
