/** @format */
// eslint-disable-next-line
import React, { Fragment, useEffect, useState } from "react";
// import { Link } from "react-router-dom";
// eslint-disable-next-line
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import styled from "styled-components";
import Adminsidebar from "./AdminSideBar/Adminsidebar";
import DarkBG from "./AdminSideBar/DarkBG";

const GoogleAccounts = () => {
	const [click2, setClick2] = useState(false);
	const [clickMenu2, setClickMenu2] = useState(false);

	//
	const GoogleBusinessDashboard = () => {
		window.open(
			"https://business.google.com/dashboard/l/07839939534137880786?hl=en",
		);
	};

	//
	const GoogleAnalyticsDashboard = () => {
		window.open(
			"https://analytics.google.com/analytics/web/#/report-home/a210313266w303672288p260913550",
		);
	};

	//

	useEffect(() => {
		setClickMenu2(click2);
	}, [click2, clickMenu2]);

	return (
		<DevQuickLinksWrapper>
			{click2 && clickMenu2 ? (
				<DarkBG setClick2={setClick2} setClickMenu2={setClickMenu2} />
			) : null}
			<div className='mx-auto'>
				<Adminsidebar
					click2={click2}
					setClick2={setClick2}
					clickMenu2={clickMenu2}
					setClickMenu2={setClickMenu2}
				/>
			</div>
			<div
				className='col-md-6 col-sm-6 offset-md-2 mt-5 mx-auto p-3'
				style={{
					border: "1px black solid",
					borderRadius: "20px",
					marginBottom: "260px",
				}}>
				<h3 className='my-5 text-center'>Check Your Google Accounts</h3>

				<div className='boxOptions' onClick={GoogleBusinessDashboard}>
					Check Your Google Business Dashboard
				</div>
				<div className='boxOptions' onClick={GoogleAnalyticsDashboard}>
					Check Google Analytics (To See Visitors)
				</div>

				<ToastContainer />
			</div>
		</DevQuickLinksWrapper>
	);
};

export default GoogleAccounts;

const DevQuickLinksWrapper = styled.div`
	.boxOptions {
		padding: 10px;
		text-align: center;
		margin: 30px 30px;
		border: solid 2px darkGrey;
		border-radius: 10px;
		cursor: pointer;
		font-size: 1.2rem;
		font-weight: bold;
		letter-spacing: 3px;
		background: darkred;
		color: white;
		transition: 0.5s;
	}

	.boxOptions:hover {
		transition: 0.5s;
		padding: 20px;
		background: darkslategray;
	}
`;
