/** @format */

import React, { Fragment, useState, useEffect } from "react";
import styled from "styled-components";
import ReactGA from "react-ga";

// import { Helmet } from "react-helmet";
import { contactUs } from "../auth/index";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";

const Contact = () => {
	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	const [values, setValues] = useState({
		name: "",
		email: "",
		subject: "",
		text: "",
		success: false,
		loading: false,
	});

	const { name, email, subject, text, loading } = values;

	const handleChange = (name) => (event) => {
		setValues({
			...values,
			error: false,
			[name]: event.target.value,
		});
	};

	const clickSubmit = (event) => {
		event.preventDefault();
		console.log("Form was submitted");
		window.scrollTo({ top: 0, behavior: "smooth" });

		contactUs({ name, email, subject, text, loading: true }).then((data) => {
			if (data.error) {
				setValues({ ...values, error: data.error, success: false });
				toast.error(data.error);
			} else {
				toast.success(SuccessfullySubmitted);

				setValues({
					subject: "",
					text: "",
					success: false,
					loading: false,
				});
			}
		});
	};

	const SuccessfullySubmitted =
		"Your form was successfully submitted. Our support team will contact you!";

	return (
		<ContactWrapper>
			{/* <Helmet>
				<meta charSet='utf-8' />
				<title>Online Store CBD - Contact us</title>
				<meta
					name='description'
					content='CBD Health - CBD is a non-psychoactive cannabinoid. It cannot be used for intoxication or to “get high”. There is a lot of research on CBD oil, a quick internet search can help you find them. 
          '
				/>
				<link rel='canonical' href='http://onlinestore.com/contact' />
				<link rel='icon' href='Frontend\src\favicon.ico' />
			</Helmet> */}
			<div>
				<div className='row mt-5 ml-3'>
					<div className='col-md-4 my-3'>
						<h3 className='text-center'>Do you have an inquiry...?</h3>
						<p className='Contact-us-paragraph mt-5'>
							Contact us and our team will respond in minutes
						</p>
						<p className='Contact-us-paragraph'>
							<div className='mt-3'>
								<strong>Business Hours:</strong> We are happy to serve you 24/7.
							</div>
							<br />
							<strong>Address:</strong> 9847 Cedar Ave, Bloomington CA 92316
							<br />
							<strong>Phone #:</strong>{" "}
							<Link to='#' onClick={() => window.open("tel:9094019583")}>
								(909) 401-9583.
							</Link>
							<br />
							<strong>Email:</strong> info@palacios-towing.com.
							<br />
						</p>
						<div className='mt-5'>
							<h3 className='text-center'>Palacios Towing</h3>

							<p className='mt-3' style={{ fontSize: "0.85rem" }}>
								&nbsp;&nbsp;&nbsp;&nbsp; We look forward to serving you and
								handling all of your towing needs.
							</p>
						</div>
					</div>
					<Fragment left>
						<div
							className='col-md-7 my-3 mx-auto p-5'
							style={{
								boxShadow: "3px 3px 3px 3px rgba(0,0,0,0.5)",
							}}>
							<Fragment duration={5000}>
								<h2 className='text-center'>Contact Us</h2>
							</Fragment>
							{loading ? (
								<h2>Loading...</h2>
							) : (
								<form className='mt-5 mr-3 ' onSubmit={clickSubmit}>
									<ToastContainer />
									{/*first:  adding your name*/}
									<div className='form-group'>
										<label
											className='float-left'
											style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
											Name:
										</label>
										<input
											type='text'
											name='name'
											onChange={handleChange("name")}
											value={name}
											className='form-control'
											placeholder='Fullname e.g.: John Don'
											required
										/>
									</div>
									{/*email:  adding your emailaddress*/}
									<div className='form-group'>
										<label
											className='float-left'
											style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
											Email Address:
										</label>

										<input
											type='email'
											name='email'
											onChange={handleChange("email")}
											value={email}
											className='form-control'
											placeholder='Email e.g.: Name@email.com'
											required
										/>
									</div>
									{/*Subject:  Adding your subject line*/}
									<div className='form-group'>
										<label
											className='float-left'
											style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
											Subject:
										</label>

										<input
											type='text'
											name='subject'
											onChange={handleChange("subject")}
											value={subject}
											className='form-control'
											placeholder='Subject'
										/>
									</div>
									{/*message */}
									<div className='form'>
										<label
											className='float-left'
											style={{ fontWeight: "bold", fontSize: "1.1rem" }}>
											Your Inquiry / Complaint:
										</label>

										<textarea
											name='text'
											className='form-control'
											onChange={handleChange("text")}
											value={text}
											rows='10'
											placeholder='Please place your message/comments here'
											required></textarea>
									</div>
									{/*message */}
									<input
										type='submit'
										value='Submit'
										className='form-control bg-primary text-white'
									/>
								</form>
							)}
						</div>
					</Fragment>

					<hr />
				</div>
			</div>
			<hr />
		</ContactWrapper>
	);
};

export default Contact;

const ContactWrapper = styled.div`
	overflow: hidden;
`;
