/** @format */

import React, { Fragment, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { signout, isAuthenticated } from "../auth";
import styled from "styled-components";
import logo from "../imgs/PalaciosTowingLogo.jpg";
import ReactGA from "react-ga";

const Navbar1 = ({
	history,
	click,
	setClick,
	clickMenu,
	setClickMenu,
	language,
	setLanguage,
}) => {
	const handleSidebar = () => {
		setClick(!click);
	};

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		// ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	const sideBar = () => {
		return (
			<React.Fragment>
				<SideWrapper show={clickMenu}>
					<ul>
						<li
							className='mt-3'
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}>
							<Link
								to='/'
								className='sidebar-link'
								onClick={() => {
									setClickMenu(false);
									setClick(false);
								}}>
								{click && clickMenu ? (
									<React.Fragment>Home</React.Fragment>
								) : null}
							</Link>
						</li>
						<li
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}>
							<Link
								to='/services'
								className='sidebar-link'
								onClick={() => {
									setClickMenu(false);
									setClick(false);
								}}>
								{click && clickMenu ? (
									<React.Fragment>Services</React.Fragment>
								) : null}
							</Link>
						</li>

						<li
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}>
							<Link
								to='/about'
								className='sidebar-link'
								onClick={() => {
									setClickMenu(false);
									setClick(false);
								}}>
								{click && clickMenu ? (
									<React.Fragment>About</React.Fragment>
								) : null}
							</Link>
						</li>
						<li
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}>
							<Link
								to='/contact'
								className='sidebar-link'
								onClick={() => {
									setClickMenu(false);
									setClick(false);
								}}>
								{click && clickMenu ? (
									<React.Fragment>Contact Us</React.Fragment>
								) : null}
							</Link>
						</li>

						{isAuthenticated() && isAuthenticated().user.role === 1 && (
							<React.Fragment>
								<li
									className='nav-item ml-5 mt-3'
									onClick={() => {
										window.scrollTo({ top: 0, behavior: "smooth" });
									}}>
									<Link
										className='nav-link '
										to='/admin/dashboard'
										onClick={() => {
											setClickMenu(false);
											setClick(false);
										}}>
										Owner Dashboard
									</Link>
								</li>
							</React.Fragment>
						)}

						{!isAuthenticated() && (
							<Fragment>
								<li
									className='nav-item ml-5 mt-3'
									onClick={() => {
										window.scrollTo({ top: 0, behavior: "smooth" });
									}}>
									<Link
										className='nav-link '
										style={{ color: "#4da6ff" }}
										to='/login'
										onClick={() => {
											setClickMenu(false);
											setClick(false);
										}}>
										Login
									</Link>
								</li>

								<li
									className='nav-item ml-5 mt-3'
									onClick={() => {
										window.scrollTo({ top: 0, behavior: "smooth" });
									}}>
									<Link
										className='nav-link callNumber'
										style={{
											color: "#cde6ff",
											fontSize: "1.2rem",
											fontWeight: "bold",
										}}
										to='/calling'
										onClick={() => {
											setClickMenu(false);
											setClick(false);
										}}>
										Call (909) 401-9583
									</Link>
								</li>

								<li
									className='nav-item ml-5 mt-3'
									onClick={() => {
										window.scrollTo({ top: 0, behavior: "smooth" });
									}}>
									<span
										className='languageStyle'
										style={{ color: "white", fontWeight: "bold" }}>
										Language
									</span>{" "}
									<span className='mx-3 btn btn-primary'>
										{language === "English" ? (
											<span
												className='btn btn-primary'
												onClick={() => {
													setLanguage("Spanish");
													setClickMenu(false);
													setClick(false);
													ReactGA.event({
														category: "User Changed Language",
														action: "User Changed Language To Spanish",
														label: "Language Changed",
													});
													// window.location.reload(false);
												}}>
												Español
											</span>
										) : (
											<span
												className='btn btn-primary'
												onClick={() => {
													setLanguage("English");
													setClickMenu(false);
													setClick(false);
													ReactGA.event({
														category: "User Changed Language",
														action: "User Changed Language To English",
														label: "Language Changed",
													});
													// window.location.reload(false);
												}}>
												English
											</span>
										)}
									</span>
								</li>
							</Fragment>
						)}

						{isAuthenticated() && (
							<li
								className='nav-item ml-5'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}>
								<span>
									<span
										className='signoutbutton nav-link'
										style={{
											cursor: "pointer",
											margin: 10,
											fontWeight: "bold",
											textDecoration: "underline",
											color: "#ff0000",
											fontStyle: "italic",
										}}
										onClick={() =>
											signout(() => {
												history.push("/");
												localStorage.removeItem("pickedServiceFirstAvailable");
												localStorage.removeItem("CustomerType");
												localStorage.removeItem("chosenDateFromFirstAvailable");
												localStorage.removeItem("barber");
												localStorage.removeItem("chosenStylistId_Store");
												localStorage.removeItem("chosenStylistUpdate");
											})
										}>
										Signout
									</span>
								</span>
							</li>
						)}
						{isAuthenticated() && (
							<li
								className='nav-item ml-5 mt-3'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}>
								<span
									className='languageStyle'
									style={{ color: "white", fontWeight: "bold" }}>
									Language
								</span>{" "}
								<span className='mx-3 btn btn-primary'>
									{language === "English" ? (
										<span
											className='btn btn-primary'
											onClick={() => {
												setLanguage("Spanish");
												setClickMenu(false);
												setClick(false);
												ReactGA.event({
													category: "User Changed Language",
													action: "User Changed Language To Spanish",
													label: "Language Changed",
												});
												// window.location.reload(false);
											}}>
											Español
										</span>
									) : (
										<span
											className='btn btn-primary'
											onClick={() => {
												setLanguage("English");
												setClickMenu(false);
												setClick(false);
												ReactGA.event({
													category: "User Changed Language",
													action: "User Changed Language To English",
													label: "Language Changed",
												});
												// window.location.reload(false);
											}}>
											English
										</span>
									)}
								</span>
							</li>
						)}
					</ul>
				</SideWrapper>
			</React.Fragment>
		);
	};
	return (
		<Nav
			className=' navbar  navbar-expand-sm nav-center py-1'
			style={{ backgroundColor: "#06192a" }}>
			{click ? (
				<i
					className='far fa-window-close nav-icon faaa-bars'
					onClick={handleSidebar}
					style={{ color: "white" }}></i>
			) : (
				<i
					className='fa fa-bars nav-icon faaa-bars'
					onClick={handleSidebar}
					style={{ color: "white" }}></i>
			)}
			{sideBar()}
			<div className='logo-type'>
				<Link
					to='/'
					onClick={() => {
						window.scrollTo({ top: 0, behavior: "smooth" });
					}}>
					<img src={logo} alt='CBD store logo' className='sinaiLogo' />
					{/* <div className='logo-type' style={{ color: "white" }}>
						Palacios Towing <br />
					</div> */}
				</Link>
			</div>
			<div className='collapse navbar-collapse menu'>
				<ul className='navbar-nav actual-list'>
					{isAuthenticated() && isAuthenticated().user.role === 1 && (
						<li className='nav-item mt-1'>
							<Link
								className='nav-link'
								to='/admin/dashboard'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}
								style={{
									color: "white",
									textDecoration: "underline",
									fontWeight: "bold",
									marginRight: "20px",
									fontStyle: "italic",
								}}>
								Hello {isAuthenticated().user.name}
							</Link>
						</li>
					)}
					{isAuthenticated() && isAuthenticated().user.role === 2 && (
						<li className='nav-item'>
							<Link
								className='nav-link'
								to='/admin/dashboard'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}
								style={{
									color: "white",
									textDecoration: "underline",
									fontWeight: "bold",
									marginRight: "20px",
									fontStyle: "italic",
								}}>
								Hello {isAuthenticated().user.name}
							</Link>
						</li>
					)}
					{!isAuthenticated() && (
						<Fragment>
							<li className='nav-item'>
								<Link
									className='nav-link '
									to='/login'
									onClick={() => {
										window.scrollTo({ top: 0, behavior: "smooth" });
									}}
									style={{
										color: "#4da6ff",
										textDecoration: "underline",
										fontWeight: "bold",
										marginRight: "20px",
										fontStyle: "italic",
										marginTop: "4px",
									}}>
									Login
								</Link>
							</li>

							<li className='nav-item'>
								<Link
									className='nav-link'
									to='/calling'
									onClick={() => {
										window.scrollTo({ top: 0, behavior: "smooth" });
									}}
									style={{
										color: "#cde6ff",
										fontSize: "1.3rem",
										fontWeight: "bold",
										textDecoration: "underline",
										marginRight: "89px",
										fontStyle: "italic",
									}}>
									Call (909) 401-9583
								</Link>
							</li>
							<li className='nav-item'>
								<span style={{ color: "white", fontWeight: "bold" }}>
									Language
								</span>{" "}
								<span className='mx-3 btn btn-primary'>
									{language === "English" ? (
										<span
											className='btn btn-primary'
											onClick={() => {
												setLanguage("Spanish");
												// window.location.reload(false);
												ReactGA.event({
													category: "User Changed Language",
													action: "User Changed Language To Spanish",
													label: "Language Changed",
												});
											}}>
											Español
										</span>
									) : (
										<span
											className='btn btn-primary'
											onClick={() => {
												setLanguage("English");
												// window.location.reload(false);
											}}>
											English
										</span>
									)}
								</span>
							</li>
						</Fragment>
					)}
					{isAuthenticated() && (
						<li className='nav-item mt-1'>
							<span
								className='nav-link'
								style={{
									cursor: "pointer",
									fontWeight: "bold",
									textDecoration: "underline",
									color: "#ff0000",
									fontStyle: "italic",
									marginRight: "100px",
								}}
								onClick={() =>
									signout(() => {
										history.push("/");
										localStorage.removeItem("pickedServiceFirstAvailable");
										localStorage.removeItem("CustomerType");
										localStorage.removeItem("chosenDateFromFirstAvailable");
										localStorage.removeItem("barber");
										localStorage.removeItem("chosenStylistId_Store");
										localStorage.removeItem("chosenStylistUpdate");
										window.scrollTo({ top: 0, behavior: "smooth" });
									})
								}>
								Signout
							</span>
						</li>
					)}
					{isAuthenticated() && (
						<li className='nav-item'>
							<span style={{ color: "white", fontWeight: "bold" }}>
								Language
							</span>{" "}
							<span className='mx-3 btn btn-primary'>
								{language === "English" ? (
									<span
										className='btn btn-primary'
										onClick={() => {
											setLanguage("Spanish");
											// window.location.reload(false);
											ReactGA.event({
												category: "User Changed Language",
												action: "User Changed Language To Spanish",
												label: "Language Changed",
											});
										}}>
										Español
									</span>
								) : (
									<span
										className='btn btn-primary'
										onClick={() => {
											setLanguage("English");
											// window.location.reload(false);
											ReactGA.event({
												category: "User Changed Language",
												action: "User Changed Language To English",
												label: "Language Changed",
											});
										}}>
										English
									</span>
								)}
							</span>
						</li>
					)}
				</ul>
			</div>
		</Nav>
	);
};

export default withRouter(Navbar1);

const Nav = styled.nav`
	img {
		width: 300px;
		height: 50px;
		object-fit: cover;
		border-radius: 10px;
	}
	background-image: linear-gradient(to right, #be0000, #260000);

	.menu {
		justify-content: flex-end;
	}
	.logo-type {
		font-size: 1rem;
		font-family: "Snell Roundhand, cursive";
		font-weight: bold;
		text-align: center;
		font-style: italic;
		display: inline-block;
		/* box-shadow: 7px 7px 5px 0px rgba(0, 0, 0, 0.1); */
		vertical-align: middle;
		/* margin-left: 4px; */
	}

	.cart-badge {
		display: none;
	}

	@media (max-width: 680px) {
		position: -webkit-sticky;
		position: sticky;
		top: 0;
		width: 100%;
		padding: 0.5rem 1.5rem;
		background: var(--mainGrey);
		border-bottom: 3px solid var(--darkGrey);
		z-index: 300;

		.cart-badge {
			border-radius: 100%;
			font-size: 12px;
			font-style: italic;
			background: #737070;
			color: white;
			text-decoration: none !important;
			display: block;
			padding: 5.2px;
			font-weight: bold;
			position: absolute;
			left: 10px;
		}

		.logo-type {
			font-size: 1rem;
			font-family: "Snell Roundhand, cursive";
			font-weight: bold;
			text-align: center;
			font-style: italic;
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0;
			/* margin-right: 2px; */
		}

		.nav-center {
			display: flex;
			align-items: center;
			justify-content: space-between;
			max-width: 1170px;
			margin: 0 auto;
		}
		.nav-icon {
			font-size: 1.35rem;
			cursor: pointer;
			margin-left: 5px;
		}

		.nav-cart {
			position: relative;
		}
		.cart-items {
			background: var(--mainGrey);
			color: black;
			font-weight: bold;
			font-size: 0.7rem;
			position: absolute;
			padding: 0 5px;
		}

		.logo-type {
			font-size: 1rem;
			font-family: "Snell Roundhand, cursive";
			font-weight: bold;
			text-align: center;
			font-style: italic;
			display: inline-block;
			vertical-align: middle;
			margin-bottom: 0;
		}
	}
	font-size: 1rem;

	li a:hover {
		background: #727272;
		text-decoration: none;
		color: var(--mainWhite) !important;
		outline-color: var(--darkGrey);
		transition: var(--mainTransition);
	}
	@media (min-width: 680px) {
		.faaa-bars {
			display: none;
		}
	}
	@media (max-width: 900px) {
		.actual-list {
			font-size: 0.7rem;
		}

		img {
			object-fit: fill !important;
			width: 150px;
			height: 100%;
			margin-right: 5px;
		}

		position: -webkit-sticky;
		position: sticky;
		top: 0;
		z-index: 200;
		padding: 1px;
		background-image: linear-gradient(to right, #be0000, #260000);
	}
`;

const SideWrapper = styled.nav`
	position: fixed;
	top: 60px;
	left: 0;
	width: 60%;
	height: 100%;
	background: var(--mainGrey);
	z-index: 300;
	border-right: 3px solid var(--darkGrey);
	transition: 0.5s;
	transform: ${(props) => (props.show ? "translateX(0)" : "translateX(-100%)")};
	background-image: linear-gradient(to right, #be0000, #260000);
	/*transform: translateX(-100%);*/ /**this will hide the side bar */
	ul {
		list-style-type: none;
		padding: 0 !important;
		font-weight: bold;
	}

	.sidebar-link {
		display: block;
		font-size: 1rem;
		text-transform: capitalize;
		color: white;
		padding: 1.1rem 1.1rem;
		background: transparent;
		font-weight: bold;
		transition: var(--mainTransition);
	}
	.sidebar-link:hover {
		background: #727272;
		color: var(--mainWhite);
		/* padding: 1rem 2rem 1rem 2rem; */
		text-decoration: none;
	}
	@media (min-width: 600px) {
		width: 20rem;
	}
	@media (max-width: 600px) {
		font-size: 0.8rem !important;
		.sidebar-link {
			font-size: 0.8rem !important;
		}

		.callNumber {
			font-size: 16px !important;
		}

		.languageStyle {
			display: none;
		}
	}
	@media (min-width: 680px) {
		display: none;
	}
`;
