/** @format */

import React, { useEffect, useState } from "react";
import "./App.css";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import ReactGA from "react-ga";
import Home from "./Pages/Home";
import OrderingPage from "./Pages/OrderingPage";
import DarkBackground from "./Navbar/DarkBackground";
import Navbar from "./Navbar/Navbar";
import NavbarTop from "./Navbar/NavbarTop";
// import Sidebar from "./Navbar/Sidebar";
import Services from "./Pages/Services";
import Pricing from "./Pages/Pricing";
import Gallery from "./Pages/Gallery";
import Contact from "./Pages/Contact";
import About from "./Pages/About";
import Login from "./Pages/Login";
import Register from "./Pages/Register";
import Footer from "./Components/Footer";

//Admin
import AdminDashboard from "./Admin/AdminDashboard";
import AdminRoute from "./auth/AdminRoute";
import AddService from "./Admin/AddService";
import UpdateService from "./Admin/UpdateService";
import HomeSpanish from "./Pages/HomeSpanish";
import OrderingPageSpanish from "./Pages/OrderingPageSpanish";
import UpdateProfile from "./Admin/UpdateProfile";
import AboutSpanish from "./Pages/AboutSpanish";
import ServicesSpanish from "./Pages/ServicesSpanish";
import DevQuickLinks from "./Admin/DevQuickLinks";
import GoogleAccounts from "./Admin/GoogleAccounts";

const App = () => {
	const [click, setClick] = useState(false);
	const [clickMenu, setClickMenu] = useState(false);
	const [language, setLanguage] = useState("English");

	useEffect(() => {
		setClickMenu(click);
		// eslint-disable-next-line
	}, [click, clickMenu]);

	const languageToggle = () => {
		console.log(language);
		localStorage.setItem("lang", JSON.stringify(language));
		// window.location.reload(false);
	};

	useEffect(() => {
		languageToggle();
		// eslint-disable-next-line
	}, [language]);

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	return (
		<BrowserRouter>
			<NavbarTop
				click={click}
				setClick={setClick}
				clickMenu={clickMenu}
				setClickMenu={setClickMenu}
				language={language}
				setLanguage={setLanguage}
			/>
			{click && clickMenu ? (
				<DarkBackground setClick={setClick} setClickMenu={setClickMenu} />
			) : null}

			<Navbar />
			<ToastContainer />
			<React.Fragment>
				<Switch>
					{language === "Spanish" ? (
						<Route path='/' exact component={HomeSpanish} />
					) : (
						<Route path='/' exact component={Home} />
					)}
					{/* <Route path='/' exact component={Home} /> */}

					{language === "Spanish" ? (
						<Route path='/calling' exact component={OrderingPageSpanish} />
					) : (
						<Route path='/calling' exact component={OrderingPage} />
					)}

					{/* <Route path='/calling' exact component={OrderingPage} /> */}
					{language === "Spanish" ? (
						<Route path='/services' exact component={ServicesSpanish} />
					) : (
						<Route path='/services' exact component={Services} />
					)}
					<Route path='/pricing' exact component={Pricing} />
					<Route path='/gallery' exact component={Gallery} />
					<Route path='/contact' exact component={Contact} />
					{language === "Spanish" ? (
						<Route path='/about' exact component={AboutSpanish} />
					) : (
						<Route path='/about' exact component={About} />
					)}
					<Route path='/login' exact component={Login} />
					<Route
						path='/register/thisonlyforfewpeopleallowedtogethereahowanbyby2239'
						exact
						component={Register}
					/>

					<AdminRoute
						path='/admin/dashboard'
						exact
						component={AdminDashboard}
					/>

					<AdminRoute
						path='/admin/create/service'
						exact
						component={AddService}
					/>
					<AdminRoute
						path='/admin/update-profile/:userId'
						exact
						component={UpdateProfile}
					/>
					<AdminRoute
						path='/admin/update-service-price/:serviceId'
						exact
						component={UpdateService}
					/>
					<AdminRoute
						exact
						path='/admin/dev-payments'
						component={DevQuickLinks}
					/>

					<AdminRoute
						exact
						path='/admin/google-accounts'
						component={GoogleAccounts}
					/>
				</Switch>
			</React.Fragment>
			<Footer />
		</BrowserRouter>
	);
};

export default App;
