/** @format */

import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { getServices } from "../ApiCore";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import PowerBySnippet from "./PowerBySnippet";

const Services = () => {
	const [allServices, setAllServices] = useState([]);

	const gettingAllServices = () => {
		getServices().then((data) => {
			if (data.error) {
				console.log(data.error);
			} else {
				setAllServices(data.filter((i) => i.activeService === true));
			}
		});
	};

	useEffect(() => {
		gettingAllServices();
	}, []);

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	return (
		<ServicesPageWrapper>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Palacios Towing | Services</title>
				<meta
					name='description'
					content='Palacios Towing Services. Fast Response And Quality Care. Please call us if you need any roadside Assistance. A Sample of our services Jump Start, Lock Out, Tire Change, Gasoline Service and Towing Services We Speak Spanish!
					Infinite-Apps... The Right Choice To Develop Your Web Application!
					This website is for Towing Businesses created by Infinite Apps / http://infinite-apps.com
					Get up and running in 1 to 3 days with your own domain name and STOP promoting others.
					We at infinite apps always trying to help businesses to grow and develop specially businesses that primarily highly dependent on scheduling appointments or meetings such as, Barber Shops, Hair Salons, Grooming Salons, Nail Salons, etc...
					We at infinite apps develop eCommerce Web Apps as well to help you easily present or offer your products without any hassel or even without being used. Take a look at Infinite Apps offers and you will absolutely be impressed with our pricing and services.
					Grooming Salons or Groomers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Groomer Dashboard, Grooming Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Hair Salons: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Barber Shops or Barbers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.'
				/>
				<link rel='canonical' href='https://palacios-towing.com/services' />
			</Helmet>
			<div className='secSection3 my-5'>
				<h1>Palacios Towing Services</h1>
				<div className='col-md-5 mx-auto'>
					<br />
					<div className='horizLine'></div>
				</div>
				<p>
					We are a family owned business working to help those in need in the
					Inland Empire. We are based out of Bloomington and can respond quickly
					to have your vehicle moving again. We are available 24 hours a day, 7
					days a week. Below are the services we currently support. Please
					contact us for assistance for your car or truck. We look foward to
					your call!
				</p>
				<div>
					<div className='container'>
						<div className='row'>
							{allServices &&
								allServices.map((s, i) => {
									return (
										<div key={i} className='col-md-5 mx-auto my-2 '>
											<div
												className='card '
												style={{
													borderRadius: "0% 10%",
													backgroundColor: "#faf7eb",
												}}>
												<div className='card-body  '>
													<div className='card-img-top center img text-center'>
														<Link
															to='/calling'
															onClick={() => {
																window.scrollTo({ top: 0, behavior: "smooth" });
															}}>
															<img
																alt={s.serviceName}
																src={s.thumbnail[0].url}
																style={{
																	height: "300px",
																	width: "300px",
																	borderRadius: "50px",
																}}
															/>
														</Link>
													</div>
													<div
														className='mt-2 mb-3'
														style={{
															fontSize: "20px",
															fontWeight: "bold",
															textAlign: "center",
															textTransform: "capitalize",
														}}>
														Service: {s.serviceName}
													</div>
													{/* {s.servicePrice === s.servicePriceDiscount ? (
														<div
															className=''
															style={{
																fontSize: "15px",
																fontWeight: "bold",
																textAlign: "center",
																textTransform: "capitalize",
															}}>
															Service Price: ${s.servicePriceDiscount} Per{" "}
															{s.miles_KM} {s.unitOfMeasureText}
														</div>
													) : (
														<div
															className=''
															style={{
																fontSize: "15px",
																fontWeight: "bold",
																textAlign: "center",
																textTransform: "capitalize",
															}}>
															Service Price:{" "}
															<s style={{ color: "red" }}> ${s.servicePrice}</s>{" "}
															<span className='ml-1'>
																{" "}
																${s.servicePriceDiscount} Per {s.miles_KM}{" "}
																{s.unitOfMeasureText}
															</span>
														</div>
													)} */}
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</div>
			<div className='mt-5'>
				<PowerBySnippet />
			</div>
		</ServicesPageWrapper>
	);
};

export default Services;

const ServicesPageWrapper = styled.div`
	.secSection3 h1 {
		font-size: 2rem;
		text-align: center;
		margin-top: 10px;
		font-weight: bold;
		letter-spacing: 3px;
	}

	.secSection3 .horizLine {
		border-bottom: #2f2727 solid 5px;
	}

	.secSection3 p {
		overflow: auto;
		margin: 20px 300px !important;
		line-height: 2;
	}

	.secSection3 .card {
		box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
		height: 100%;
		width: 100%;
		background-image: linear-gradient(to right, #f9f9f9, #bebebe);
	}

	@media (max-width: 1200px) {
		.secSection3 h1 {
			font-size: 1.5rem;
		}

		.secSection3 p {
			margin: 20px 20px !important;
		}

		.secSection3 .card {
			box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
			height: 100%;
			width: 100%;
			background-image: linear-gradient(to right, #f9f9f9, #bebebe);
		}
	}
`;
