/** @format */

import React, { useEffect } from "react";
import styled from "styled-components";
// import AboutPhoto from "../imgs/traffic-3098747_1920.jpg";
import AboutPhoto from "../imgs/JesusTruck.jpg";
import ReactGA from "react-ga";
import Helmet from "react-helmet";
import PowerBySnippet from "./PowerBySnippet";

const AboutSpanish = () => {
	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	return (
		<AboutPageWrapper>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Palacios Towing | Quienes somos nosotras</title>
				<meta
					name='description'
					content='Servicios de Grúa Palacios. Respuesta rápida y atención de calidad. Llámanos si necesitas asistencia en carretera.
					Una muestra de nuestros servicios Jump Start, Lock Out, Cambio de llantas, Servicio de gasolina y Servicios de remolque
					¡Nosotros hablamos español!
					Infinite-Apps... ¡La elección correcta para desarrollar su aplicación web!
					Este sitio web es para empresas de remolque creadas por Infinite Apps  http://infinite-apps.com
					Ponte en marcha en 1 a 3 días con tu propio nombre de dominio y DEJA de promocionar a otros.
					En infinite apps siempre tratamos de ayudar a las empresas a crecer y desarrollarse, especialmente aquellas que dependen en gran medida de la programación de citas o reuniones, como peluquerías, peluquerías, peluquerías, salones de uñas, etc.
					En infinite apps también desarrollamos aplicaciones web de comercio electrónico para ayudarlo a presentar u ofrecer fácilmente sus productos sin problemas o incluso sin ser utilizados. Eche un vistazo a las ofertas de Infinite Apps y quedará absolutamente impresionado con nuestros precios y servicios.
					Salones de aseo o Groomers: infinitas aplicaciones ofrecen nombre de dominio, alojamiento privado de Linux, panel de administración, panel de Groomer, programador de tienda de aseo, panel de usuario, sistema de SMS, sistema de inicio de sesión/registro y muchas otras características.
					Peluquerías: infinitas aplicaciones ofrecen nombre de dominio, alojamiento privado de Linux, panel de administración, panel de estilista, programador de tiendas, panel de usuario, sistema de SMS, sistema de inicio de sesión/registro y muchas otras funciones más.
					Peluquerías o barberos: las aplicaciones infinitas ofrecen nombre de dominio, alojamiento privado de Linux, panel de administración, panel de estilista, programador de tiendas, panel de usuario, sistema de SMS, sistema de inicio de sesión/registro y muchas otras funciones más.'
				/>
				<link rel='canonical' href='https://palacios-towing.com/about' />
			</Helmet>
			<div className='container my-5'>
				<h1 className='title text-center '>ABOUT US</h1>
				<div className='col-md-5 mx-auto mb-5'>
					<br />
					<div className='horizLine'></div>
				</div>
				<div className='row'>
					<div className='col-md-6 about-us'>
						<p className='about-title'>Why we're different</p>
						<ul>
							<li>
								Como un negocio operado y de propiedad familiar que comenzó en
								2021, en Palacios Towing están dedicados a quienes experimentan
								una avería desafortunada y necesita que su vehículo vuelva a la
								carretera rápidamente. Nos enorgullecemos de estar disponibles
								las 24 horas del día, los 7 días de la semana, mientras rápido,
								confiable, honesto y asequible. Nuestra oferta de camiones
								Servicio de remolque de emergencia, arrancadores, bloqueos,
								cambios de neumáticos y servicios de gasolina en los condados de
								San Bernardino y Riverside. Esperamos poder servirle y manejar
								todo su remolque. necesidades.
							</li>
						</ul>
					</div>
					<div className='col-md-6 imgdiv'>
						<img src={AboutPhoto} className='img-fluid' alt='PalacioTowing' />
					</div>
				</div>
			</div>
			<div className='mt-5'>
				<PowerBySnippet />
			</div>
		</AboutPageWrapper>
	);
};

export default AboutSpanish;

const AboutPageWrapper = styled.section`
	background: #f8f9fa;
	padding-bottom: 50px;
	padding-top: 50px;

	ul {
		list-style: none;
	}

	.about-title {
		font-size: 40px;
		font-weight: 600;
		margin-top: 8%;
	}
	#about-us ul li {
		margin: 10px 0;
	}

	#about-us ul {
		margin-left: 20px;
	}

	.imgdiv {
		transform: rotate(-8deg);
		box-shadow: 3px 10px 3px 10px rgba(0, 0, 0, 0.1);
	}

	.horizLine {
		border-bottom: #2f2727 solid 5px;
	}
`;
