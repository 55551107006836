/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import HeroCompHome from "../Components/HeroCompHome";
import HookImg from "../imgs/Hook.jpg";
import ReactGA from "react-ga";
import { getServices } from "../ApiCore";
import Helmet from "react-helmet";
import PowerBySnippet from "./PowerBySnippet";

const Home = () => {
	const [allServices, setAllServices] = useState([]);

	const gettingAllServices = () => {
		getServices().then((data) => {
			if (data.error) {
				console.log(data.error);
			} else {
				setAllServices(data.filter((i) => i.activeService === true));
			}
		});
	};

	useEffect(() => {
		gettingAllServices();
	}, []);

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	return (
		<HomePageWrapper>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Palacios Towing | Fast Response And Quality Care</title>
				<meta
					name='description'
					content='
					Palacios Towing Services. Fast Response And Quality Care.
					Please call us if you need any roadside Assistance.
					A Sample of our services Jump Start, Lock Out, Tire Change, Gasoline Service and Towing Services
					We Speak Spanish!
					Infinite-Apps... The Right Choice To Develop Your Web Application!
					This website is for Towing Businesses created by Infinite Apps / http://infinite-apps.com
					Get up and running in 1 to 3 days with your own domain name and STOP promoting others.
					We at infinite apps always trying to help businesses to grow and develop specially businesses that primarily highly dependent on scheduling appointments or meetings such as, Barber Shops, Hair Salons, Grooming Salons, Nail Salons, etc...
					We at infinite apps develop eCommerce Web Apps as well to help you easily present or offer your products without any hassel or even without being used. Take a look at Infinite Apps offers and you will absolutely be impressed with our pricing and services.
					Grooming Salons or Groomers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Groomer Dashboard, Grooming Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Hair Salons: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.
					Barber Shops or Barbers: infinite apps offer Domain Name, Private Linux Hosting, Admin Dashboard, Stylist Dashboard, Store Scheduler, User Dashboard, SMS System, Login/Registering System and many more other features.'
				/>
				<link rel='canonical' href='https://palacios-towing.com' />
			</Helmet>
			<div>
				<HeroCompHome />
			</div>
			<div className='secSection3 my-5'>
				<h1>Palacios Towing Services</h1>
				<div className='col-md-5 mx-auto'>
					<br />
					<div className='horizLine'></div>
				</div>
				<p>
					We are a family owned business working to help those in need in the
					Inland Empire. We are based out of Bloomington and can respond quickly
					to have your vehicle moving again. We are available 24 hours a day, 7
					days a week. Below are the services we currently support. Please
					contact us for assistance for your car or truck. We look foward to
					your call!
				</p>
				<div>
					<div className='container'>
						<div className='row'>
							{allServices &&
								allServices.map((s, i) => {
									return (
										<div key={i} className='col-md-5 mx-auto my-2 '>
											<div
												className='card '
												style={{
													borderRadius: "0% 10%",
													backgroundColor: "#faf7eb",
												}}>
												<div className='card-body  '>
													<div className='card-img-top center img text-center'>
														<Link
															to='/calling'
															onClick={() => {
																window.scrollTo({ top: 0, behavior: "smooth" });
															}}>
															<img
																alt={s.serviceName}
																src={s.thumbnail[0].url}
																style={{
																	height: "300px",
																	width: "300px",
																	borderRadius: "50px",
																}}
															/>
														</Link>
													</div>
													<div
														className='mt-2 mb-3'
														style={{
															fontSize: "20px",
															fontWeight: "bold",
															textAlign: "center",
															textTransform: "capitalize",
														}}>
														Service: {s.serviceName}
													</div>
													{/* {s.servicePrice === s.servicePriceDiscount ? (
														<div
															className=''
															style={{
																fontSize: "15px",
																fontWeight: "bold",
																textAlign: "center",
																textTransform: "capitalize",
															}}>
															Service Price: ${s.servicePriceDiscount} Per{" "}
															{s.miles_KM} {s.unitOfMeasureText}
														</div>
													) : (
														<div
															className=''
															style={{
																fontSize: "15px",
																fontWeight: "bold",
																textAlign: "center",
																textTransform: "capitalize",
															}}>
															Service Price:{" "}
															<s style={{ color: "red" }}> ${s.servicePrice}</s>{" "}
															<span className='ml-1'>
																{" "}
																${s.servicePriceDiscount} Per {s.miles_KM}{" "}
																{s.unitOfMeasureText}
															</span>
														</div>
													)} */}
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</div>
			<PowerBySnippet />
			<hr />

			<div className='secSection'>
				<h1>PALACIOS TOWING SERVICE | JESUS PALACIOS</h1>
				<div className='col-md-5 mx-auto'>
					<br />
					<div className='horizLine'></div>
				</div>
				<p>
					PALACIOS Towing has been serving and earning the trust of the
					community of San Bernardino and Riverside counties. Being a reputable
					towing, recovery, and roadside assistance service company in San
					Bernardino and Riverside counties, we only rely on highly skilled tow
					truck drivers and the latest equipment. Reasonable rates coupled with
					high-quality service make us one of the most trusted towing companies
					in Inland Empire. Our tow experts use fully certified equipment and
					accessories to ensure your safety and peace of mind. The following are
					some of the features of our services that help us stand out:
				</p>
				<div className='container my-5'>
					<div className='row'>
						<div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 my-auto'>
							<ul>
								<li>24 Hour emergency service</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Highly experienced and friendly staff</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Comprehensive range of expertise</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Safe &amp; secure</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Quick &amp; affordable</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Fully-insured</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Environment-friendly</li>
								<div className='col-md-10'>
									<hr />
								</div>
							</ul>
						</div>

						<div className='col-xs-12 col-sm-12 col-md-6 col-lg-5'>
							<img className='imgHook' src={HookImg} alt='Infinite-Apps' />
						</div>
					</div>
				</div>
			</div>
			<br />
			<br />
			{/* <div className='secSection2'>
				<h1>Price Update</h1>
				<div className='col-md-5 mx-auto'>
					<br />
					<div className='horizLine'></div>
				</div>
				<p>
					Due to the recent increase in our commercial insurance premiums,
					Palacios Towing is required to adjust our prices. Starting January
					2021, our prices will increase by approximately 3-5% for our towing
					and roadside assistance services. This is not something we look
					forward to, but it is required in order for us to continue to provide
					our clients with premium service including full insurance coverage. At
					Palacios Towing, we do our best to provide our clients with
					competitive market rates across the board. As valued clients, we thank
					you for your business and understanding. Should our insurance market
					rates change, we will adjust our pricing accordingly.
				</p>
			</div>
			<br /> */}
			<hr />
		</HomePageWrapper>
	);
};

export default Home;

const HomePageWrapper = styled.div`
	.secSection h1 {
		font-size: 2rem;
		text-align: center;
		margin-top: 10px;
		font-weight: bold;
		letter-spacing: 3px;
	}

	.secSection .horizLine {
		border-bottom: #2f2727 solid 5px;
	}

	.secSection p {
		margin: 20px 300px !important;
		line-height: 2;
	}

	.secSection ul {
		list-style: none;
	}

	.secSection ul li:before {
		content: "✓";
		color: green;
		font-weight: bold;
		margin-right: 10px;
		font-size: 20px;
	}

	.imgHook {
		width: 150%;
		border: solid black 3px;
		border-radius: 20px;
		box-shadow: 10px 2px 10px 2px rgba(0, 0, 0, 0.2);
		transform: rotate(-5deg);
	}

	.secSection2 h1 {
		font-size: 2rem;
		text-align: center;
		margin-top: 10px;
		font-weight: bold;
		letter-spacing: 3px;
	}

	.secSection2 .horizLine {
		border-bottom: #2f2727 solid 5px;
	}

	.secSection2 p {
		margin: 20px 300px !important;
		line-height: 2;
	}

	.secSection3 h1 {
		font-size: 2rem;
		text-align: center;
		margin-top: 10px;
		font-weight: bold;
		letter-spacing: 3px;
	}

	.secSection3 .horizLine {
		border-bottom: #2f2727 solid 5px;
	}

	.secSection3 p {
		margin: 20px 300px !important;
		line-height: 2;
	}

	.secSection3 .card {
		box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
		height: 100%;
		width: 100%;
		background-image: linear-gradient(to right, #f9f9f9, #bebebe);
	}

	@media (max-width: 1200px) {
		.secSection p {
			margin: 20px 20px !important;
			/* font-weight: bold; */
		}

		.secSection h1 {
			font-size: 1.5rem;
		}

		.secSection2 p {
			margin: 20px 20px !important;
			/* font-weight: bold; */
		}

		.secSection2 h1 {
			font-size: 1.5rem;
		}

		.imgHook {
			width: 95%;
		}

		.secSection3 h1 {
			font-size: 1.5rem;
		}

		.secSection3 p {
			margin: 20px 20px !important;
		}

		.secSection3 .card {
			box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
			height: 100%;
			width: 100%;
			background-image: linear-gradient(to right, #f9f9f9, #bebebe);
		}
	}
`;
