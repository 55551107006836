import React from 'react'
import styled from 'styled-components'


const Gallary = () => {

    return (
        <GallaryPageWrapper>
            <div>
                Hi From Gallary Page
            </div>
        </GallaryPageWrapper>
    )
}

export default Gallary

const GallaryPageWrapper = styled.div`


`