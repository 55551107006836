/** @format */

import React from "react";
import styled from "styled-components";
import home2 from "../imgs/JesusTruck.jpg";
import wave1 from "../imgs/wave1.png";
// eslint-disable-next-line
import play from "../imgs/play.png";
import { Link } from "react-router-dom";

const HeroCompHomeEspanish = () => {
	return (
		<HeroCompHomeEspanishWrapper className='banner'>
			<div className='container'>
				<div className='row'>
					<div className='col-md-7'>
						<h2 className='promo-title text-center'>
							Servicio de Gruas Palacios
						</h2>
						<br />
						<h4 className='mt-2 text-center'>
							Respuesta rápida y atención de calidad
						</h4>
						<br />
						<Link style={{ fontWeight: "bold" }} to='/calling'>
							{/* <img src={play} className='play-btn' alt='infinite-apps' /> */}
							Haga clic aquí para obtener asistencia en la carretera:{" "}
							<span className='phoneNumberStyling mx-1'>+19094019583</span>{" "}
						</Link>
					</div>
					<div className='col-md-5 text-center home2'>
						<img src={home2} className='img-fluid' alt='infinite-apps' />
					</div>
				</div>
			</div>
			<div className='wave1div'>
				<img src={wave1} className='bottom-img' alt='infinite-apps' />
			</div>
		</HeroCompHomeEspanishWrapper>
	);
};

export default HeroCompHomeEspanish;

const HeroCompHomeEspanishWrapper = styled.section`
	background-image: linear-gradient(to right, #4da6ff, #0000ff);
	color: #fff;
	padding-top: 8%;
	text-align: center;

	.phoneNumberStyling {
		font-size: 1.7rem;
		text-shadow: 1px 1px 5px;
		letter-spacing: 6px;
		text-decoration: underline;
		margin-left: 50px !important;
	}

	.promo-title {
		font-size: 2.5rem;
		font-weight: bold;
	}

	.play-btn {
		width: 100px;
		margin: 20px;
		border-radius: 20px;
	}
	a {
		color: #fff;
		text-decoration: none;
	}

	.wave1div {
		overflow: hidden !important;
	}
	.bottom-img {
		width: 100vw;
		/* height: 100vh; */
	}

	.home2 {
		box-shadow: 5px 5px 5px 5px rgba(0, 0, 0, 0.2);
		transform: rotate(-8deg);
		border-radius: 20px;
		background-image: linear-gradient(to right, #f9f9f9, #c8c8c8);
	}

	h4 {
		font-size: 1.8rem;
		font-weight: bold;
		overflow: hidden;
	}

	@media (max-width: 1025px) {
		.home2 {
			width: 100%;
			text-align: center;
			margin: 30px 30px;
		}
		.phoneNumberStyling {
			font-size: 2rem;
			margin-left: 15% !important;
		}

		.play-btn {
			display: none;
		}

		.promo-title {
			font-size: 2rem;
			font-weight: bold;
		}
	}
`;
