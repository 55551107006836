/** @format */

import React, { useEffect, useState } from "react";
import styled from "styled-components";
import HookImg from "../imgs/Hook.jpg";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";
import { getServices } from "../ApiCore";
import Helmet from "react-helmet";
import HeroCompHomeEspanish from "../Components/HeroCompHomeEspanish";
import PowerBySnippet from "./PowerBySnippet";

const HomeSpanish = () => {
	const [allServices, setAllServices] = useState([]);

	const gettingAllServices = () => {
		getServices().then((data) => {
			if (data.error) {
				console.log(data.error);
			} else {
				setAllServices(data.filter((i) => i.activeService === true));
			}
		});
	};

	useEffect(() => {
		gettingAllServices();
	}, []);

	useEffect(() => {
		ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENTID);
		// To Report Page View
		ReactGA.pageview(window.location.pathname + window.location.search);
		// eslint-disable-next-line
	}, []);

	return (
		<HomePageWrapper>
			<Helmet>
				<meta charSet='utf-8' />
				<title>Palacios Towing | Respuesta rápida y atención de calidad</title>
				<meta
					name='description'
					content='
					Servicios de Grúa Palacios. Respuesta rápida y atención de calidad.
					Llámanos si necesitas asistencia en carretera.
					Una muestra de nuestros servicios Jump Start, Lock Out, Cambio de llantas, Servicio de gasolina y Servicios de remolque
					¡Nosotros hablamos español!
					Infinite-Apps... ¡La elección correcta para desarrollar su aplicación web!
					Este sitio web es para empresas de remolque creadas por Infinite Apps  http://infinite-apps.com
					Ponte en marcha en 1 a 3 días con tu propio nombre de dominio y DEJA de promocionar a otros.
					En infinite apps siempre tratamos de ayudar a las empresas a crecer y desarrollarse, especialmente aquellas que dependen en gran medida de la programación de citas o reuniones, como peluquerías, peluquerías, peluquerías, salones de uñas, etc.
					En infinite apps también desarrollamos aplicaciones web de comercio electrónico para ayudarlo a presentar u ofrecer fácilmente sus productos sin problemas o incluso sin ser utilizados. Eche un vistazo a las ofertas de Infinite Apps y quedará absolutamente impresionado con nuestros precios y servicios.
					Salones de aseo o Groomers: infinitas aplicaciones ofrecen nombre de dominio, alojamiento privado de Linux, panel de administración, panel de Groomer, programador de tienda de aseo, panel de usuario, sistema de SMS, sistema de inicio de sesión/registro y muchas otras características.
					Peluquerías: infinitas aplicaciones ofrecen nombre de dominio, alojamiento privado de Linux, panel de administración, panel de estilista, programador de tiendas, panel de usuario, sistema de SMS, sistema de inicio de sesión/registro y muchas otras funciones más.
					Peluquerías o barberos: las aplicaciones infinitas ofrecen nombre de dominio, alojamiento privado de Linux, panel de administración, panel de estilista, programador de tiendas, panel de usuario, sistema de SMS, sistema de inicio de sesión/registro y muchas otras funciones más.'
				/>
				<link rel='canonical' href='https://palacios-towing.com' />
			</Helmet>
			<div>
				<HeroCompHomeEspanish />
			</div>
			<hr />
			<div className='secSection3 my-5'>
				<h1>Servicio de Gruas Palacios</h1>
				<div className='col-md-5 mx-auto'>
					<br />
					<div className='horizLine'></div>
				</div>
				<p>
					Somos una empresa familiar que trabaja para ayudar en caso que
					necesite algun servicio en carretera en el Inland Empire. Estamos
					ubicados en Bloomington y podemos responder rápidamente para ayudarlo
					con su vehículo. Estamos disponibles las 24 horas del día, 7 dias por
					semana. A continuación se muestran los servicios que apoyamos
					actualmente. Por favor contáctenos para obtener asistencia para su
					automóvil o camión. Esperamos con ansias ¡tu llamada!
				</p>
				<div>
					<div className='container'>
						<div className='row'>
							{allServices &&
								allServices.map((s, i) => {
									return (
										<div key={i} className='col-md-5 mx-auto my-2 '>
											<div
												className='card '
												style={{
													borderRadius: "0% 10%",
													backgroundColor: "#faf7eb",
												}}>
												<div className='card-body  '>
													<div className='card-img-top center img text-center'>
														<div>
															<Link
																to='/calling'
																onClick={() => {
																	window.scrollTo({
																		top: 0,
																		behavior: "smooth",
																	});
																}}>
																<img
																	alt={s.serviceName}
																	src={s.thumbnail[0].url}
																	style={{
																		height: "300px",
																		width: "300px",
																		borderRadius: "50px",
																	}}
																/>
															</Link>
														</div>
													</div>
													<div
														className='mt-2 mb-3'
														style={{
															fontSize: "20px",
															fontWeight: "bold",
															textAlign: "center",
															textTransform: "capitalize",
														}}>
														Servicio: {s.serviceName_Spanish}
													</div>
													{/* {s.servicePrice === s.servicePriceDiscount ? (
														<div
															className=''
															style={{
																fontSize: "15px",
																fontWeight: "bold",
																textAlign: "center",
																textTransform: "capitalize",
															}}>
															Precio del servicio: ${s.servicePriceDiscount} Per{" "}
															{s.miles_KM} {s.unitOfMeasureText}
														</div>
													) : (
														<div
															className=''
															style={{
																fontSize: "15px",
																fontWeight: "bold",
																textAlign: "center",
																textTransform: "capitalize",
															}}>
															Precio del servicio:{" "}
															<s style={{ color: "red" }}> ${s.servicePrice}</s>{" "}
															<span className='ml-1'>
																{" "}
																${s.servicePriceDiscount} Per {s.miles_KM}{" "}
																{s.unitOfMeasureText}
															</span>
														</div>
													)} */}
												</div>
											</div>
										</div>
									);
								})}
						</div>
					</div>
				</div>
			</div>
			<PowerBySnippet />

			<hr />
			<div className='secSection'>
				<h1>PALACIOS TOWING SERVICE | JESUS PALACIOS</h1>
				<div className='col-md-5 mx-auto'>
					<br />
					<div className='horizLine'></div>
				</div>
				<p>
					PALACIOS Towing viene sirviendo y ganándose la confianza de la
					comunidad de los condados de San Bernardino y Riverside. Somos una
					respetable empresa de servicios de grua, y asistencia en carretera en
					los Condados de San Bernardino y Riverside, solo confiamos en
					remolques altamente calificados choferes y equipos de última
					generación. Tarifas razonables junto con El servicio de alta calidad
					nos convierte en una de las empresas de remolque más confiables en el
					Inland Empire. Nuestros expertos en remolques utilizan equipos
					totalmente certificados y accesorios para garantizar su seguridad y
					tranquilidad. Los siguientes son los servicios que brindamos que nos
					ayudan a destacar:
				</p>
				<div className='container my-5'>
					<div className='row'>
						<div className='col-xs-12 col-sm-12 col-md-6 col-lg-5 my-auto'>
							<ul>
								<li>Servicio de emergencia 24 horas</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Personal altamente experimentado y amigable</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Amplia gama de conocimientos</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Seguro &amp; segura</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Rápida &amp; affordable</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Totalmente-asegurada</li>
								<div className='col-md-10'>
									<hr />
								</div>
								<li>Respetuosa del medio ambiente</li>
								<div className='col-md-10'>
									<hr />
								</div>
							</ul>
						</div>

						<div className='col-xs-12 col-sm-12 col-md-6 col-lg-5'>
							<img className='imgHook' src={HookImg} alt='Infinite-Apps' />
						</div>
					</div>
				</div>
			</div>
			<br />
			<br />
			{/* <div className='secSection2'>
				<h1>Actualización de precio</h1>
				<div className='col-md-5 mx-auto'>
					<br />
					<div className='horizLine'></div>
				</div>
				<p>
					Debido al reciente aumento de nuestras primas de seguros comerciales,
					Se requiere Remolque Palacios para ajustar nuestros precios. A partir
					de enero 2021, nuestros precios aumentarán en aproximadamente un 3-5%
					para nuestro remolque y servicios de asistencia en carretera. Esto no
					es algo que miramos Reenviar, pero es necesario para que podamos
					seguir proporcionando a nuestros clientes con un servicio premium que
					incluye una cobertura de seguro completa. A Palacios Towing, hacemos
					todo lo posible para ofrecer a nuestros clientes tasas de mercado
					competitivas en todos los ámbitos. Como valiosos clientes, agradecemos
					usted por su negocio y comprensión. ¿Debería nuestro mercado de
					seguros las tarifas cambian, ajustaremos nuestros precios en
					consecuencia.
				</p>
			</div>
			<br /> */}
			<hr />
		</HomePageWrapper>
	);
};

export default HomeSpanish;

const HomePageWrapper = styled.div`
	.secSection h1 {
		font-size: 2rem;
		text-align: center;
		margin-top: 10px;
		font-weight: bold;
		letter-spacing: 3px;
	}

	.secSection .horizLine {
		border-bottom: #2f2727 solid 5px;
	}

	.secSection p {
		margin: 20px 300px !important;
		line-height: 2;
	}

	.secSection ul {
		list-style: none;
	}

	.secSection ul li:before {
		content: "✓";
		color: green;
		font-weight: bold;
		margin-right: 10px;
		font-size: 20px;
	}

	.imgHook {
		width: 150%;
		border: solid black 3px;
		border-radius: 20px;
		box-shadow: 10px 2px 10px 2px rgba(0, 0, 0, 0.2);
		transform: rotate(-5deg);
	}

	.secSection2 h1 {
		font-size: 2rem;
		text-align: center;
		margin-top: 10px;
		font-weight: bold;
		letter-spacing: 3px;
	}

	.secSection2 .horizLine {
		border-bottom: #2f2727 solid 5px;
	}

	.secSection2 p {
		margin: 20px 300px !important;
		line-height: 2;
	}

	.secSection3 h1 {
		font-size: 2rem;
		text-align: center;
		margin-top: 10px;
		font-weight: bold;
		letter-spacing: 3px;
	}

	.secSection3 .horizLine {
		border-bottom: #2f2727 solid 5px;
	}

	.secSection3 p {
		margin: 20px 300px !important;
		line-height: 2;
	}

	.secSection3 .card {
		box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
		height: 100%;
		width: 100%;
		background-image: linear-gradient(to right, #f9f9f9, #bebebe);
	}

	@media (max-width: 1200px) {
		.secSection p {
			margin: 20px 20px !important;
			/* font-weight: bold; */
		}

		.secSection h1 {
			font-size: 1.5rem;
		}

		.secSection2 p {
			margin: 20px 20px !important;
			/* font-weight: bold; */
		}

		.secSection2 h1 {
			font-size: 1.5rem;
		}

		.imgHook {
			width: 95%;
		}

		.secSection3 h1 {
			font-size: 1.5rem;
		}

		.secSection3 p {
			margin: 20px 20px !important;
		}

		.secSection3 .card {
			box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.3);
			height: 100%;
			width: 100%;
			background-image: linear-gradient(to right, #f9f9f9, #bebebe);
		}
	}
`;
