/** @format */

import React from "react";
import { Link, withRouter } from "react-router-dom";
import { isAuthenticated } from "../auth/index";
import styled from "styled-components";

const isActive = (history, path) => {
	if (history.location.pathname === path) {
		return {
			color: "white",
			background: "#a5a397",
			fontWeight: "bold",
			borderRadius: "10px",
			// textDecoration: "underline",
		};
	} else {
		return { color: "black", fontWeight: "bold" };
	}
};

const isActive2 = (history, path) => {
	if (history.location.pathname === path) {
		return {
			color: "white",
			background: "#0056b6",
			fontWeight: "bold",
			borderRadius: "10px",
			// textDecoration: "underline",
		};
	} else {
		return { color: "black", fontWeight: "bold" };
	}
};

const Navbar = ({ history }) => {
	return (
		<Nav
			className=' navbar  navbar-expand-sm'
			style={{ backgroundColor: "	#f2f2f2" }}>
			<div className='collapse navbar-collapse '>
				<ul
					className='navbar-nav mx-auto navbar-expand '
					style={{ backgroundColor: "	#f2f2f2" }}>
					<li className='nav-item'>
						<Link
							className='nav-link'
							style={isActive(history, "/")}
							to='/'
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}>
							Home
						</Link>
					</li>
					<li className='nav-item'>
						<Link
							className='nav-link'
							style={isActive(history, "/services")}
							to='/services'
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}>
							Services
						</Link>
					</li>
					{/* <li className='nav-item'>
						<Link
							className='nav-link'
							style={isActive(history, "/gallery")}
							to='/gallery'
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}>
							Our Gallery
						</Link>
					</li> */}
					<li className='nav-item'>
						<Link
							className='nav-link'
							style={isActive(history, "/contact")}
							to='/contact'
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}>
							Contact Us
						</Link>
					</li>
					<li className='nav-item'>
						<Link
							className='nav-link'
							style={isActive(history, "/about")}
							to='/about'
							onClick={() => {
								window.scrollTo({ top: 0, behavior: "smooth" });
							}}>
							About Us
						</Link>
					</li>

					{/* {isAuthenticated() && isAuthenticated().user.role === 0 && (
						<li className='nav-item ml-5'>
							<Link
								className='nav-link'
								style={isActive2(history, "/dashboard")}
								to='/dashboard'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}>
								My Dasboard/Account
							</Link>
						</li>
					)} */}

					{/* {isAuthenticated() && isAuthenticated().user.role === 1 && (
						<li className='nav-item'>
							<Link
								className='nav-link'
								style={isActive(history, "/dashboard")}
								to='/dashboard'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}>
								Owner Regular Account
							</Link>
						</li>
					)} */}
					{isAuthenticated() && isAuthenticated().user.role === 1 && (
						<li className='nav-item ml-4'>
							<Link
								className='nav-link'
								style={isActive2(history, "/admin/dashboard")}
								to='/admin/dashboard'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}>
								Owner Dashboard
							</Link>
						</li>
					)}
					{/* {isAuthenticated() && isAuthenticated().user.role === 3 && (
						<li className='nav-item ml-4'>
							<Link
								className='nav-link'
								style={isActive2(history, "/book-appointment-from-store")}
								to='/book-appointment-from-store'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}>
								Store Scheduler
							</Link>
						</li>
					)}
					{isAuthenticated() && isAuthenticated().user.role === 1 && (
						<li className='nav-item ml-4'>
							<Link
								className='nav-link'
								style={isActive2(history, "/book-appointment-from-store")}
								to='/book-appointment-from-store'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}>
								Store Scheduler
							</Link>
						</li>
					)}
					{isAuthenticated() && isAuthenticated().user.role === 2 && (
						<li className='nav-item'>
							<Link
								className='nav-link'
								style={isActive(history, "/dashboard")}
								to='/dashboard'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}>
								Stylist Regular Account
							</Link>
						</li>
					)} */}
					{/* {isAuthenticated() && isAuthenticated().user.role === 2 && (
						<li className='nav-item ml-4'>
							<Link
								className='nav-link'
								style={isActive2(history, "/stylist/dashboard")}
								to='/stylist/dashboard'
								onClick={() => {
									window.scrollTo({ top: 0, behavior: "smooth" });
								}}>
								Stylist Dashboard
							</Link>
						</li>
					)} */}
				</ul>
			</div>
		</Nav>
	);
};

export default withRouter(Navbar);

const Nav = styled.nav`
	position: -webkit-sticky;
	position: sticky;
	top: 0;
	z-index: 100;
	padding: 1px;
	margin-top: 2px;
	box-shadow: 2px 5px 2px 2px rgba(0, 0, 0, 0.2);
	margin-bottom: 3px;

	li a {
		font-size: 0.95rem;
	}
	.nav-link {
		color: black;
	}

	.nav-link:hover {
		color: white !important;
	}

	li {
		margin: 0px 12px 0px 0px;
	}

	li a:hover {
		background: #727272;
		color: var(--mainWhite);
		outline-color: var(--darkGrey);
		transition: var(--mainTransition);
	}

	@media (max-width: 900px) {
		li a {
			color: black;
			font-size: 0.7rem;
			margin: 0px;
		}
		li {
			margin: 0px 0px 0px 0px;
		}
	}

	@media (max-width: 680px) {
		display: none;
	}
`;
